import React from 'react'
import { Grid } from '@mui/material'
import { StyledAssetTitle } from '../../Dashboard/Styled'

interface IPanelLabelProps {
  isBorrow?: boolean
}

const PanelLabel = (props: IPanelLabelProps): JSX.Element => {
  const { isBorrow } = props
  return (
    <Grid
      container
      spacing={0}
      bgcolor="rgba(255, 255, 255, 0.1)"
    >
      <Grid item sm={5}>
        <StyledAssetTitle>Withdraw amount</StyledAssetTitle>
      </Grid>
      <Grid item sm={4} sx={{ textAlign: 'right' }}>
        <StyledAssetTitle>Date</StyledAssetTitle>
      </Grid>
      <Grid item sm={3} sx={{ textAlign: 'right' }}>
        <StyledAssetTitle>Status</StyledAssetTitle>
      </Grid>
    </Grid>
  )
}

export default PanelLabel
