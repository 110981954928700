import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { useCopyClipboard } from '../../hooks'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckIcon from '@mui/icons-material/Check'

export default function CopyHelper(props: { toCopy: string; children?: React.ReactNode }): JSX.Element {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <Tooltip title={isCopied ? 'Copied' : 'Copy address to clipboard'} placement="top" arrow>
      <IconButton aria-label="close" size="small" color="primary" onClick={() => setCopied(props.toCopy)}>
        {isCopied ? <CheckIcon sx={{ color: '#06f7f7' }} /> : <ContentCopyIcon sx={{ color: '#ffffff' }} />}
      </IconButton>
    </Tooltip>
  )
}
