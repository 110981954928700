import React from 'react'
import { Grid, Stack, Tooltip } from '@mui/material'
import { StyledAssetRow, StyledAssetText } from '../Styled'
import CSPRLogo from 'assets/casper-red.svg'
import { format } from 'date-fns'
import { toRoundedReadableNumber } from 'utils/number'
import BigNumber from 'bignumber.js'
import getConfig from 'config'

interface IPanelStakeProps {
  stake: any
  rate: string | number
  apr: any
}

const PanelStake = (props: IPanelStakeProps): JSX.Element => {
  const { stake, rate, apr } = props
  const _rate = new BigNumber(rate).div(1e9).toString()
  const _stReward = new BigNumber(stake?.stAmount).multipliedBy(_rate).toString()
  const reward = new BigNumber(_stReward).minus(stake?.amount).toFixed(0)
  return (
    <StyledAssetRow onClick={() => window.open(`${getConfig().explorerURL}/deploy/${stake?.deployHash}`, '_blank')}>
      <Grid container alignItems="center" spacing={0} px={2.5} py={2}>
        <Grid item sm={3}>
          <Stack direction="row" alignItems="center" justifyContent="start">
            <img src={CSPRLogo} alt="title" style={{ maxWidth: 14, marginRight: 5 }} />
            <StyledAssetText>
              {toRoundedReadableNumber({
                decimals: 9,
                number: stake?.amount?.toString(),
                precision: 3,
              })}
            </StyledAssetText>
          </Stack>
        </Grid>
        <Grid item sm={2} sx={{ textAlign: 'right' }}>
          <StyledAssetText>{apr} %</StyledAssetText>
        </Grid>
        <Grid item sm={3} sx={{ textAlign: 'right' }}>
          <Stack direction="row" alignItems="center" justifyContent="end">
            <img src={CSPRLogo} alt="title" style={{ maxWidth: 14, marginRight: 5 }} />
            <StyledAssetText>
              {toRoundedReadableNumber({
                decimals: 9,
                number: Number(reward) < 0 ? '0' : reward,
                precision: 3,
              })}
            </StyledAssetText>
          </Stack>
        </Grid>
        <Grid item sm={4} sx={{ textAlign: 'right' }}>
          <Tooltip title={format(new Date(stake?.timestamp * 1000), 'dd/MM/yyyy HH:mm:ss')} placement="top-end" arrow>
            <StyledAssetText>{format(new Date(stake?.timestamp * 1000), 'dd/MM/yyyy')}</StyledAssetText>
          </Tooltip>
        </Grid>
      </Grid>
    </StyledAssetRow>
  )
}

export default PanelStake
