export default function getConfig(env: string = process.env.REACT_APP_NETWORK) {
  switch (env) {
    case 'testnet':
      return {
        network: 'casper-test',
        explorerURL: 'https://testnet.cspr.live',
        nodeURL: 'https://testnet.casper-node.tor.us',
        makeAPI: 'https://event-store-api-clarity-testnet.make.services',
        urlAPI: 'https://stcspr-api.paradiso.io',
        minStaking: 500,
        liquidityStaking: {
          contract: 'dc66375d1f6f80e1858785cb94ef38d44ded74527175d6b0e0dca06d3feb9ad2',
          contractPackageHash: 'a090c9401d64d02b5a15561b13323dcf38595daedc5be1de3f6d52ff07c09647',
        },
        projectId: '7ca193f0eff28082b3f3df1cbb359b76',
      }
    default:
      return {
        network: 'casper',
        explorerURL: 'https://cspr.live',
        nodeURL: 'https://mainnet.casper.validationcloud.io/v1/X7J4SJgMI0Apjn7fGRjjHcPBTKeL0KWgaPykjhkw3EI',
        makeAPI: 'https://event-store-api-clarity-mainnet.make.services',
        urlAPI: 'https://stcspr-api.paradiso.io',
        minStaking: 500,
        liquidityStaking: {
          contract: '57a79404d5d4be9334b083512ad673da900523bcc0d7867e2316de4c93547cd6',
          contractPackageHash: '65cf6b0585ddd362d2c57314043b36a679af501f686771ccb67dc5976968efe0',
        },
        projectId: '7ca193f0eff28082b3f3df1cbb359b76',
      }
  }
}
