import React, { useEffect, useState } from 'react'
import { Box, Container, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import Layout from 'components/Layout'
import { PieChart } from 'react-minimal-pie-chart'
import { useAccount, useUserBalance } from 'state/wallet/hooks'
import CustomBox from 'components/CustomBox'
import { useBalance, useCSPRBalance, useCSPRPrice, useCSPRTotalSupply, useInfo, useStakes } from 'hooks'
import { toRoundedReadableNumber } from 'utils/number'
import { StyledAssetWrap, StyledDivider } from './Styled'
import PanelLabel from './components/PanelLabel'
import PanelStake from './components/PanelStake'
import BigNumber from 'bignumber.js'
import StakingPage from 'pages/Staking/Staking'
import WithdrawalPage from 'pages/Withdrawal'
import axios from 'axios'
import getConfig from 'config'
import { useGlobalModal } from 'state/modal/hooks'
import IBalance from 'type/IBalance'
import DashboardPage from './Dashboard'

const tabs = [
  {tabIndex: 1, label: 'Dashboard'},
  {tabIndex: 2, label: 'Staking'},
  {tabIndex: 3, label: 'Withdrawals'}
]
const URL_API = getConfig().urlAPI

const HomePage = (): JSX.Element => {
  const [csprPrice, setCSPRPrice] = useState<number>(0)
  const [stakes, setStakes] = useState<any[]>([])
  const [data, setData] = useState([
    { title: 'CSPR', value: 10, color: '#A5EB9D' },
    { title: 'stCSPR', value: 15, color: '#938EE5' },
  ])
  const [totalWallet, setTotalWallet] = useState('')
  const [tabIndex, setTabIndex] = useState(1)
  const [info, setInfo] = useState<any>()
  const [marketCap, setMarketCap] = useState<number>(0)
  const [balance, setBalance] = useState<IBalance>({cspr: '0', stCSPR: '0'})
  const [isLoading, setLoading] = useState<boolean>(false)

  //const { balance, isLoading } = useUserBalance()
  const csprPriceCallback = useCSPRPrice()
  const account = useAccount()
  //const info = useInfo()
  const stakesCallback = useStakes(account)
  const { openWalletModal } = useGlobalModal()
  const totalSupplyCallback = useCSPRTotalSupply()

  const balanceCallback = useCSPRBalance(account)
  const balanceSTCallback = useBalance(account, getConfig().liquidityStaking.contract)

  useEffect(() => {
    getBalance()
  }, [account])

  const getBalance = async () => {
    setLoading(true)
    const _balance = await balanceCallback()
    const _balanceST = await balanceSTCallback()
    setBalance({ cspr: _balance.toString(), stCSPR: _balanceST })
    setLoading(false)
  }

  useEffect(() => {
    ; (async () => {
      const _stakes = await stakesCallback()
      setStakes(_stakes)
    })()
  }, [account])

  useEffect(() => {
    ; (async () => {
      const _price = await csprPriceCallback()
      setCSPRPrice(_price)
      const _totalSupply = await totalSupplyCallback()
      const _marketCap = Number(_price) * Number(_totalSupply)
      setMarketCap(_marketCap)
    })()
  }, [])

  useEffect(() => {
    getInfo()
  }, [tabIndex])

  useEffect(() => {
    if (!isLoading) {
      setData([
        { title: 'CSPR', value: Math.ceil(Number(balance.cspr)), color: '#3ff5e8' },
        { title: 'CSPR', value: Math.ceil(Number(balance.stCSPR)), color: '#154ba0' },
      ])
    }
  }, [balance, isLoading])

  useEffect(() => {
    const _balance = new BigNumber(balance.cspr).div(1e9).toNumber()
    const _balanceStCSPR = new BigNumber(balance.stCSPR).div(1e9).toNumber()
    const stCSPRPrice = new BigNumber(info?.lsInfo?.rate ?? 1).div(1e9).toNumber() * csprPrice
    const valueBalanceCSPR = _balance * csprPrice
    const valueBalanceStCSPR = _balanceStCSPR * (csprPrice * new BigNumber(info?.lsInfo?.rate ?? 1).div(1e9).toNumber())

    const _totalWallet = valueBalanceCSPR + valueBalanceStCSPR
    setTotalWallet(_totalWallet.toFixed(2))
  }, [balance, csprPrice, info])

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_API}/lsInfo`)

      if (response.status === 200 && response.data) {
        setInfo(response.data)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const handleChangeTab = (index) => {
    if (account) {
      if (index == 1) {
        setTabIndex(index)
      } else {
        if (!isLoading) {
          setTabIndex(index)
        }
      }
    } else {
      setTabIndex(index)
      // if (index == 1) {
      //   setTabIndex(index)
      // } else {
      //   openWalletModal()
      // }
    }
  }

  return (
    <Layout>
      <Container maxWidth="xl">
        <Box sx={{ borderRadius: '0.5rem' }}>
          <Box sx={{ borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', backgroundColor: '#154ba0', padding: '0rem 2rem' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
              <Typography variant='h2' sx={{ color: '#fff', marginBottom: '2rem', display: {xs: 'none', md: 'block'} }}>Liquid Staking</Typography>
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                sx={{ backgroundColor: '#727eaa', borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem', marginTop: '2rem'}}
              >
                {tabs.map((items) => (
                  <div
                    onClick={() => handleChangeTab(items.tabIndex)}
                    style={{
                      padding: '1rem 1.5rem',
                      borderTopRightRadius: '0.5rem',
                      borderTopLeftRadius: '0.5rem',
                      backgroundColor: `${tabIndex == items.tabIndex ? '#fff' : 'transparent'}`,
                      cursor: 'pointer'
                    }}
                  >
                    <Typography sx={{color: `${tabIndex == items.tabIndex ? '#154ba0' : '#fff'}`}}>{items.label}</Typography>
                  </div>
                ))}
              </Stack>
              <Typography variant='h2' sx={{ color: 'rgba(255, 255, 255, 0)', marginBottom: '2rem', display: {xs: 'none', md: 'block'} }}>Liquid Staking</Typography>
            </Stack>
          </Box>
          <Box sx={{ borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem', padding: '2rem', backgroundColor: '#fff' }}>
            {tabIndex == 1 && (
              <DashboardPage />
            )}
            {tabIndex == 2 && (
              <StakingPage
                hasConnected={account ? true : false}
                accountConnected={account}
                onStakeSucces={() => setTabIndex(1)}
              />
            )}
            {tabIndex == 3 && <WithdrawalPage hasConnected={account ? true : false} accountConnected={account}/> }
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default HomePage
