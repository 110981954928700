import React from 'react'
import {Box, BoxProps} from '@mui/material'

interface IBoxProps extends BoxProps {
  children: React.ReactNode
}

const CustomBox = ({children, ...props}: IBoxProps): JSX.Element => {
  return (
    <Box
      sx={{
        padding: { xs: '30px 16px', md: '40px 30px' },
        background: '#f1f2f6',
        border: '1px solid rgba(255, 255, 255, 0.15)',
        boxShadow: '0px 8px 30px rgba(26, 43, 86, 0.07)',
        backdropFilter: 'blur(15px)',
        borderRadius: '20px',
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default CustomBox
