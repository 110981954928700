import { useEffect, useState } from 'react'
import getConfig from '../config'
import axios from 'axios'

const URL_API = getConfig().urlAPI
const STAKING_CONTRACT_PACKAGE_HASH = getConfig().liquidityStaking.contractPackageHash
export const useInfo = (): any => {
  const [info, setInfo] = useState<{}>({})
  useEffect(() => {
    const getInfo = async () => {
      try {
        const response = await axios.get(`${URL_API}/lsInfo`)

        if (response.status === 200 && response.data) {
          setInfo(response.data)
        }
      } catch (error: any) {
        console.error(error)
      }
    }
    getInfo()
  }, [])

  return info
}

export const useContractHash = (): string => {
  const [contractHash, setContractHash] = useState<string>('')
  useEffect(() => {
    const getInfo = async () => {
      try {
        const response = await axios.get(
          `${URL_API}/getActiveContractHash/${STAKING_CONTRACT_PACKAGE_HASH}`,
        )

        if (response.status === 200 && response.data) {
          setContractHash(response.data.activeContractHash)
        }
      } catch (error: any) {
        console.error(error)
      }
    }
    getInfo()
  }, [])

  return contractHash
}
