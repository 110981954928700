import { styled } from '@mui/material/styles'

export const StyledDivider = styled('span')`
  display: block;
  width: 4px;
  height: 20px;
  border-radius: 4px;
  background: ${props => props.color ? props.color : "#A5EB9D"};
`

export const StyledAssetTitle = styled('label')`
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
  color: #727eaa;
`

export const StyledAssetRow = styled('div')`
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
`

export const StyledAssetWrap = styled('div')`
  border-radius: 0 0 8px 8px;
  overflow: hidden;
`

export const StyledAssetText = styled('p')`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #154ba0;
`
