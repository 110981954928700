import React from 'react'
import { Grid, Stack, Tooltip } from '@mui/material'
import { StyledAssetRow, StyledAssetText } from '../../Dashboard/Styled'
import CSPRLogo from 'assets/casper-red.svg'
import SuccessIcon from 'assets/success.png'
import PendingIcon from 'assets/pending.png'
import { format } from 'date-fns'
import { toRoundedReadableNumber } from 'utils/number'
import getConfig from 'config'

interface IPanelStakeProps {
  withdrawal: any
}

const PanelStake = (props: IPanelStakeProps): JSX.Element => {
  const { withdrawal } = props
  return (
    <StyledAssetRow
      onClick={() =>
        window.open(
          `${getConfig().explorerURL}/deploy/${
            withdrawal?.isFullfilled === true ? withdrawal?.fullfilDeploy : withdrawal?.deployHash
          }`,
          '_blank',
        )
      }
    >
      <Grid container alignItems="center" spacing={0} px={2.5} py={2}>
        <Grid item sm={5}>
          <Stack direction="row" alignItems="center" justifyContent="start">
            <img src={CSPRLogo} alt="title" style={{ maxWidth: 14, marginRight: 5 }} />
            <StyledAssetText>
              {toRoundedReadableNumber({
                decimals: 9,
                number: withdrawal?.amount?.toString(),
                precision: 3,
              })}
            </StyledAssetText>
          </Stack>
        </Grid>
        <Grid item sm={4} sx={{ textAlign: 'right' }}>
          <Tooltip
            title={format(new Date(withdrawal?.timestamp * 1000), 'dd/MM/yyyy HH:mm:ss')}
            placement="top-end"
            arrow
          >
            <StyledAssetText>{format(new Date(withdrawal?.timestamp * 1000), 'dd/MM/yyyy')}</StyledAssetText>
          </Tooltip>
        </Grid>
        <Grid item sm={3} sx={{ textAlign: 'right' }}>
          <Tooltip title={withdrawal?.isFullfilled === true ? 'Success' : 'Pending'} placement="top-end" arrow>
            <img
              src={withdrawal?.isFullfilled === true ? SuccessIcon : PendingIcon}
              alt={withdrawal?.isFullfilled === true ? 'Success' : 'Pending'}
              style={{ maxWidth: 20 }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </StyledAssetRow>
  )
}

export default PanelStake
