import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Container, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import Layout from 'components/Layout'
import { StyledInput, StyledInputIcon, StyledInputWrap, StyledMaxBtn } from '../Staking/Styled'
import { LoadingButton } from '@mui/lab'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { toRoundedReadableNumber } from 'utils/number'
import { getActiveRPC, getDeployFunction, waitForDeployExecution } from 'utils/utils'
import { useAccount, useConnectorId, useUserBalance, useWalletProvider } from 'state/wallet/hooks'
import getConfig from 'config'
import Big from 'big.js'
import { useTransactionAdder } from 'state/transactions/hooks'
import CustomBox from 'components/CustomBox'
import CasperLogo from 'assets/casper-gray.png'
import { CasperClient, CLPublicKey, CLValueBuilder, decodeBase16, DeployUtil, RuntimeArgs } from 'casper-js-sdk'
import { useActiveWeb3React, useBalance, useCSPRBalance, useInfo, useWithdrawals } from 'hooks'
import BigNumber from 'bignumber.js'
import { useGlobalModal } from 'state/modal/hooks'
import PanelLabel from './components/PanelLabel'
import PanelStake from './components/PanelStake'
import { useWalletConnect } from 'state/modal/useWalletConnect'
import { ConnectorNames } from 'connectors'
import IBalance from 'type/IBalance'

const CHAIN_NAME = getConfig().network

interface IWithdrawlalPage {
  hasConnected: boolean
  accountConnected?: string
  onWithdrawlSuccess?: () => void
}

const WithdrawalPage = (props: IWithdrawlalPage): JSX.Element => {
  const { hasConnected, accountConnected, onWithdrawlSuccess } = props
  const [amount, setAmount] = useState<string | number>()
  const [withdrawals, setWithdrawals] = useState<any[]>([])

  const [modalTitle, setModalTitle] = useState<string>('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [balanceOfUser, setBalanceOfUser] = useState<IBalance>({ cspr: '0', stCSPR: '0' })
  const [connected, setConnected] = useState(false)
  const [accConnected, setAccConnected] = useState('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoading1, setLoading1] = useState<boolean>(false)
  const [balance, setBalance] = useState<IBalance>({
    cspr: '0',
    stCSPR: '0',
  })
  const [withdrawlSuccess, setWithdrawlSuccess] = useState<boolean>(false)

  const account = useAccount()
  //const { balance, isLoading } = useUserBalance()
  const { openWalletModal } = useGlobalModal()
  const info = useInfo()
  const stakingContract = getConfig().liquidityStaking.contract
  const { connector } = useActiveWeb3React()
  const connectorId = useConnectorId()
  const addTransaction = useTransactionAdder()
  const provider = useWalletProvider()
  const withdrawalsCallback = useWithdrawals(account)
  const { signDeploy: walletConnectSignDeploy } = useWalletConnect(false)
  const balanceCallback = useCSPRBalance(account)
  const balanceSTCallback = useBalance(account, getConfig().liquidityStaking.contract)

  useEffect(() => {
    getBalance()
  }, [account])

  const getBalance = async () => {
    setLoading(true)
    const _balance = await balanceCallback()
    const _balanceST = await balanceSTCallback()
    setBalance({ cspr: _balance.toString(), stCSPR: _balanceST })
    setLoading(false)
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  const handleMaxInput = () => {
    const _inputAmount = new Big(Number(balance.stCSPR)).div(10 ** 9).toFixed()
    setAmount(_inputAmount)
  }

  const onWithdrawal = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      setModalTitle('Request Withdrawal')
      console.log('preparing onWithdrawal')
      console.log('account', account)
      console.log('provider', provider)

      if (account) {
        const gasFee = 30000000000
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, CHAIN_NAME, 1, 1800000)
        const contractHashAsByteArray = decodeBase16(stakingContract)
        const _amount = new BigNumber(amount).multipliedBy(10 ** 9).toString()
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'request_withdrawal',
            RuntimeArgs.fromMap({
              amount: CLValueBuilder.u512(_amount),
            }),
          ),
          DeployUtil.standardPayment(gasFee),
        )
        console.log()
        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const RPC = await getActiveRPC()
          const casperClient = new CasperClient(RPC)
          const hash = await getDeployFunction(
            account,
            casperClient,
            connectorId,
            deploy,
            provider,
            json,
            connector,
            walletConnectSignDeploy,
          )

          if (hash) {
            try {
              await addTransaction(hash, {
                summary: `Request withdrawal ${amount} stCSPR`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            } catch (error: any) {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              // toast.error(error)
            }
          }

          const [_deploy, _raw] = await waitForDeployExecution(casperClient, hash)

          if (_deploy) {
            if (connected && account == accConnected) {
              setLoading1(true)
              const _balance = await balanceCallback()
              const _balanceST = await balanceSTCallback()
              setBalanceOfUser({ cspr: _balance.toString(), stCSPR: _balanceST })
              setLoading1(false)
              const _balanceOfUser = { cspr: _balance.toString(), stCSPR: _balanceST }
              const _blanceFromStorage = localStorage.getItem('balance')
              if (_blanceFromStorage) {
                localStorage.removeItem('balance')
              }
              localStorage.setItem('balance', JSON.stringify(_balanceOfUser))
            } else {
              await getBalance()
            }
            setAmount(0)
          }
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  useEffect(() => {
    //console.log('connectde', hasConnected)
    setConnected(hasConnected)
    setAccConnected(accountConnected)
  }, [])

  useEffect(() => {
    const _balanceOfUserStr = localStorage.getItem('balance')
    const _balanceOfUser = JSON.parse(_balanceOfUserStr)
    //console.log('balance', _balanceOfUser)
    setBalanceOfUser(_balanceOfUser)
  }, [])

  useEffect(() => {
    ; (async () => {
      const _withdrawals = await withdrawalsCallback()
      setWithdrawals(_withdrawals)
    })()
  }, [account])

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} sx={{ mb: '20px' }}>
          <Typography variant="h1" sx={{ marginBottom: '12px', color: '#727eaa' }}>
            Withdrawals
          </Typography>
          <Typography variant="subtitle1" color='#727eaa'>Redeem your stCSPR for CSPR</Typography>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <CustomBox sx={{ background: 'transparent' }}>
            <StyledInputWrap>
              <StyledInputIcon alt="CSPR" src={CasperLogo} />
              <StyledInput
                value={amount}
                placeholder="stCSPR Amount"
                type="number"
                autoFocus
                fullWidth
                onChange={(event) => setAmount(Number(event.target.value) < 0 ? '0' : event.target.value)}
              />
              <StyledMaxBtn variant="contained" size="small" sx={{ background: '#727eaa' }} onClick={handleMaxInput}>
                MAX
              </StyledMaxBtn>
            </StyledInputWrap>
            {!account ? (
              <Button variant="contained" color="primary" onClick={() => openWalletModal()} fullWidth>
                Connect Wallet
              </Button>
            ) : (
              <LoadingButton
                variant="contained"
                fullWidth
                onClick={() => onWithdrawal()}
                disabled={false}
                loading={false}
              >
                Request withdrawal
              </LoadingButton>
            )}
            <Box
              sx={{ padding: '24px 20px', background: 'rgba(255, 255, 255, 0.03)', borderRadius: '10px' }}
              mt={6}
              mb={2}
            >
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>You will receive</Typography>
                <Typography variant="body2" color='#154ba0'>
                  {amount === undefined
                    ? 0
                    : Number(amount) * new BigNumber(info?.lsInfo?.rate ?? '0').div(1e9).toNumber()}{' '}
                  CSPR
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>Max unlock cost</Typography>
                <Typography variant="body2" color='#154ba0'>Free</Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>Max transaction cost</Typography>
                <Typography variant="body2" color='#154ba0'>8 CSPR</Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>Exchange rate</Typography>
                <Typography variant="body2" color='#154ba0'>
                  1 stCSPR = {new BigNumber(info?.lsInfo?.rate ?? '0').div(1e9).toString()} CSPR
                </Typography>
              </Stack>
            </Box>
          </CustomBox>
        </Grid>
        <Grid item xs={12} md={6} xl={5}>
          <CustomBox mb={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderColor: 'rgba(255, 255, 255, 0.15)',
                    height: '39px',
                    alignSelf: 'center',
                  }}
                />
              }
              spacing={2}
            >
              <Box>
                <Typography sx={{ mb: '12px', fontSize: 15, color: '#727eaa' }}>
                  stCSPR Balance
                </Typography>
                {!connected ? (
                  account ? (
                    isLoading ? (
                      <Skeleton sx={{ bgcolor: '#727eaa' }} />
                    ) : (
                      <Typography variant="h2" component="p" color='#154ba0'>
                        {toRoundedReadableNumber({
                          decimals: 9,
                          number: balance.stCSPR,
                          precision: 3,
                        })}{' '}
                        stCSPR
                      </Typography>
                    )
                  ) : (
                    <Typography variant="h2" component="p" color='#154ba0'>
                      0 stCSPR
                    </Typography>
                  )
                ) : (
                  account ? (
                    account == accConnected ? (
                      isLoading1 ? (
                        <Skeleton sx={{ bgcolor: '#727eaa' }} />
                      ) : (
                        <Typography variant="h2" component="p" color='#154ba0'>
                          {toRoundedReadableNumber({
                            decimals: 9,
                            number: balanceOfUser.stCSPR,
                            precision: 3,
                          })}{' '}
                          stCSPR
                        </Typography>
                      )
                    ) : (
                      isLoading ? (
                        <Skeleton sx={{ bgcolor: '#727eaa' }} />
                      ) : (
                        <Typography variant="h2" component="p" color='#154ba0'>
                          {toRoundedReadableNumber({
                            decimals: 9,
                            number: balance.stCSPR,
                            precision: 3,
                          })}{' '}
                          stCSPR
                        </Typography>
                      )
                    )

                  ) : (
                    <Typography variant="h2" component="p" color='#154ba0'>
                      0 stCSPR
                    </Typography>
                  )

                )}
              </Box>
              <Box>
                <Typography sx={{ mb: '12px', fontSize: 15, color: '#727eaa' }}>APY</Typography>
                <Typography variant="h2" component="p" sx={{ color: '#154ba0' }}>
                  {info?.lsInfo?.apr} %
                </Typography>
              </Box>
            </Stack>
          </CustomBox>
          <Box mb={6}>
            <Typography variant="h2" mb={3} color='#154ba0'>
              Transaction History
            </Typography>
            <CustomBox>
              <PanelLabel />
              {withdrawals?.length === 0 && <Typography color='#154ba0'>No withdrawal requests detected</Typography>}
              {withdrawals?.map((withdrawal, index) => (
                <PanelStake key={index} withdrawal={withdrawal} />
              ))}
            </CustomBox>
          </Box>
        </Grid>
      </Grid>

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title={modalTitle}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default WithdrawalPage
