import { useCallback } from 'react'
import axios from 'axios'
import getConfig from 'config'
import { getAccountHash } from 'utils/utils'

const URL_API = getConfig().urlAPI
export const useWithdrawals = (account: string | undefined | null): (() => Promise<any[]>) => {
  return useCallback(async (): Promise<any[]> => {
    let result = []
    const accountHash = getAccountHash(account)

    try {
      if (accountHash === '') return
      const response = await axios.get(`${URL_API}/withdrawal/${accountHash}`)

      if (response.status === 200 && response.data) {
        result = response.data.withdrawals
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [account])
}
