import { getActiveRPC } from 'utils/utils'
import Client, { HTTPTransport, RequestManager } from '@open-rpc/client-js'

export const getClient = (provider: string) => {
  let transport
  if (typeof provider === 'string') {
    let providerUrl = provider.endsWith('/') ? provider.substring(0, provider.length - 1) : provider
    providerUrl = providerUrl.endsWith('/rpc') ? providerUrl : providerUrl + '/rpc'
    transport = new HTTPTransport(providerUrl)
  } else {
    // transport = new ProviderTransport(provider)
  }
  const requestManager = new RequestManager([transport])
  const client = new Client(requestManager)
  return client
}

export const getStateRootHash = async (blockHashBase16?: any) => {
  const RPC = await getActiveRPC()
  const client = getClient(RPC)

  return await client
    .request({
      method: 'chain_get_state_root_hash',
      params: blockHashBase16 ? [{ Hash: blockHashBase16 }] : [],
    })
    .then((res) => res.state_root_hash)
}

export async function getDictionaryItemByName(
  stateRootHash: string,
  contractHash: string,
  dictionaryName: string,
  dictionaryItemKey: string,
) {
  const RPC = await getActiveRPC()
  const client = getClient(RPC)

  const res = await client.request({
    method: 'state_get_dictionary_item',
    params: [
      stateRootHash,
      {
        ContractNamedKey: {
          key: contractHash,
          dictionary_name: dictionaryName,
          dictionary_item_key: dictionaryItemKey,
        },
      },
    ],
  })

  if (res.error) {
    return res
  } else {
    const storedValueJson = res.stored_value
    return storedValueJson
  }
}

export async function getDeployInfo(deployHash: string, finalizedApprovals?: boolean, props?: any) {
  const RPC = await getActiveRPC()
  const client = getClient(RPC)
  const params: any[] = [deployHash]
  if (finalizedApprovals) {
    params.push(finalizedApprovals)
  }

  return await client.request(
    {
      method: 'info_get_deploy',
      params,
    },
    props?.timeout,
  )
}

export async function getBlockInfo(blockHash, props?) {
  const RPC = await getActiveRPC()
  const client = getClient(RPC)

  return await client
    .request(
      {
        method: 'chain_get_block',
        params: [
          {
            Hash: blockHash,
          },
        ],
      },
      props?.timeout,
    )
    .then((res) => {
      if (res.block !== null && res.block.hash.toLowerCase() !== blockHash.toLowerCase()) {
        throw new Error('Returned block does not have a matching hash.')
      }
      return res
    })
}
