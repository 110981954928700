import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface GlobalModalState {
  openWalletModal?: boolean
  openSettingModal?: boolean
  slippage?: number
  transactionSpeed?: string
}

export const globalModalInitialState: GlobalModalState = {
  openWalletModal: false,
  openSettingModal: false,
  slippage: 0.05,
  transactionSpeed: 'normal',
}

export const GlobalModalSlice = createSlice({
  name: 'globalModal',
  initialState: globalModalInitialState,
  reducers: {
    openWalletModal: (state) => ({ ...state, openWalletModal: true }),
    closeWalletModal: (state) => ({ ...state, openWalletModal: false }),
    openSettingsModal: (state) => ({ ...state, openSettingModal: true }),
    closeSettingsModal: (state) => ({ ...state, openSettingModal: false }),
    changeSetting: (_state, action: PayloadAction<GlobalModalState>) => ({
      ...globalModalInitialState,
      ...action.payload,
      openSettingModal: true,
    }),
  },
})

export const GlobalModalActions = GlobalModalSlice.actions
export const GlobalModalReducer = GlobalModalSlice.reducer
