import React from 'react'
import { Box, Fade, IconButton, Modal as ModalMUI, ModalProps, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const Modal = (props: ModalProps) => {
  return (
    <ModalMUI {...props}>
      <Fade in={props.open}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -60%)',
            width: 480,
            maxWidth: '90%',
            maxHeight: '100%',
            p: 3,
            pb: 5,
            backgroundColor: 'f1f2f6',
            border: '1px solid rgba(255, 255, 255, 0.15)',
            backdropFilter: 'blur(75px)',
            borderRadius: '20px',
          }}
        >
          <Box mb={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h2" color="#727eaa">{props.title ? props.title : 'Modal Title'}</Typography>
              {/*
              // @ts-ignore */}
              <IconButton aria-label="close modal wallet" size="medium" sx={{ color: '#727eaa' }} onClick={props.onClose}>
                <CloseIcon color="inherit" fontSize="medium" />
              </IconButton>
            </Stack>
          </Box>
          <Box>{props.children}</Box>
        </Box>
      </Fade>
    </ModalMUI>
  )
}

export default Modal
