import { ConnectorNames } from 'connectors'

export const NetworkContextName = 'NETWORK'
export const ConnectorLocalStorageKey = 'connectorId'
export const NATIVE_TOKEN_ADDERSS = '0x1111111111111111111111111111111111111111'
export const SUPPORTED_NFT_COLLECTIONS_URL_EVM = 'https://raw.githubusercontent.com/dotoracle/evm-contract-lists/main'
export const SUPPORTED_NFT_COLLECTIONS_URL_CASPER =
  'https://raw.githubusercontent.com/dotoracle/casper-contract-hash/master/nftconfig.json'
export const WALLETCONNECT_ACCOUNT = 'WALLETCONNECT_ACCOUNT'
export const WALLETCONNECT_SESSION_TOPIC = 'WALLETCONNECT_SESSION_TOPIC'

export interface WalletInfo {
  connectorId: string
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  CASPERSIGNER: {
    connectorId: ConnectorNames.CasperSigner,
    name: 'Casper Signer',
    iconName: 'casper.png',
    description: '',
    href: null,
    color: '#E8831D',
  },
  CASPERWALLET: {
    connectorId: ConnectorNames.CasperWallet,
    name: 'Casper Wallet',
    iconName: 'casper.png',
    description: '',
    href: null,
    color: '#E8831D',
  },
  CASPERDASH: {
    connectorId: ConnectorNames.CasperDash,
    name: 'Casper Dash',
    iconName: 'casper.png',
    description: '',
    href: null,
    color: '#E8831D',
  },
  TOTUSWALLET: {
    connectorId: ConnectorNames.TorusWallet,
    name: 'Torus Wallet',
    iconName: 'torus.png',
    description: '',
    href: null,
    color: '#E8831D',
  },
}