import React, { useEffect, useState } from 'react'
import { Box, Container, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import Layout from 'components/Layout'
import { PieChart } from 'react-minimal-pie-chart'
import { useAccount, useUserBalance } from 'state/wallet/hooks'
import CustomBox from 'components/CustomBox'
import { useBalance, useCSPRBalance, useCSPRPrice, useCSPRTotalSupply, useInfo, useStakes } from 'hooks'
import { toRoundedReadableNumber } from 'utils/number'
import { StyledAssetWrap, StyledDivider } from './Styled'
import PanelLabel from './components/PanelLabel'
import PanelStake from './components/PanelStake'
import BigNumber from 'bignumber.js'
import StakingPage from 'pages/Staking/Staking'
import WithdrawalPage from 'pages/Withdrawal'
import axios from 'axios'
import getConfig from 'config'
import { useGlobalModal } from 'state/modal/hooks'
import IBalance from 'type/IBalance'

const tabs = [
  { tabIndex: 1, label: 'Dashboard' },
  { tabIndex: 2, label: 'Staking' },
  { tabIndex: 3, label: 'Withdrawals' }
]
const URL_API = getConfig().urlAPI

const DashboardPage = (): JSX.Element => {
  const [csprPrice, setCSPRPrice] = useState<number>(0)
  const [stakes, setStakes] = useState<any[]>([])
  const [data, setData] = useState([
    { title: 'CSPR', value: 10, color: '#A5EB9D' },
    { title: 'stCSPR', value: 15, color: '#938EE5' },
  ])
  const [totalWallet, setTotalWallet] = useState('')
  const [tabIndex, setTabIndex] = useState(1)
  const [info, setInfo] = useState<any>()
  const [marketCap, setMarketCap] = useState<number>(0)
  const [balance, setBalance] = useState<IBalance>({ cspr: '0', stCSPR: '0' })
  const [isLoading, setLoading] = useState<boolean>(false)

  //const { balance, isLoading } = useUserBalance()
  const csprPriceCallback = useCSPRPrice()
  const account = useAccount()
  //const info = useInfo()
  const stakesCallback = useStakes(account)
  const { openWalletModal } = useGlobalModal()
  const totalSupplyCallback = useCSPRTotalSupply()

  const balanceCallback = useCSPRBalance(account)
  const balanceSTCallback = useBalance(account, getConfig().liquidityStaking.contract)

  useEffect(() => {
    getBalance()
  }, [account])

  const getBalance = async () => {
    setLoading(true)
    const _balance = await balanceCallback()
    const _balanceST = await balanceSTCallback()
    setBalance({ cspr: _balance.toString(), stCSPR: _balanceST })
    setLoading(false)
  }

  useEffect(() => {
    ; (async () => {
      const _stakes = await stakesCallback()
      setStakes(_stakes)
    })()
  }, [account])

  useEffect(() => {
    ; (async () => {
      const _price = await csprPriceCallback()
      setCSPRPrice(_price)
      const _totalSupply = await totalSupplyCallback()
      const _marketCap = Number(_price) * Number(_totalSupply)
      setMarketCap(_marketCap)
    })()
  }, [])

  useEffect(() => {
    getInfo()
  }, [tabIndex])

  useEffect(() => {
    if (!isLoading) {
      setData([
        { title: 'CSPR', value: Math.ceil(Number(balance.cspr)), color: '#3ff5e8' },
        { title: 'CSPR', value: Math.ceil(Number(balance.stCSPR)), color: '#154ba0' },
      ])
    }
  }, [balance, isLoading])

  useEffect(() => {
    const _balance = new BigNumber(balance.cspr).div(1e9).toNumber()
    const _balanceStCSPR = new BigNumber(balance.stCSPR).div(1e9).toNumber()
    const stCSPRPrice = new BigNumber(info?.lsInfo?.rate ?? 1).div(1e9).toNumber() * csprPrice
    const valueBalanceCSPR = _balance * csprPrice
    const valueBalanceStCSPR = _balanceStCSPR * (csprPrice * new BigNumber(info?.lsInfo?.rate ?? 1).div(1e9).toNumber())

    const _totalWallet = valueBalanceCSPR + valueBalanceStCSPR
    setTotalWallet(_totalWallet.toFixed(2))
  }, [balance, csprPrice, info])

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_API}/lsInfo`)

      if (response.status === 200 && response.data) {
        setInfo(response.data)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const handleChangeTab = (index) => {
    if (account) {
      if (index == 1) {
        setTabIndex(index)
      } else {
        if (!isLoading) {
          setTabIndex(index)
        }
      }
    } else {
      setTabIndex(index)
      // if (index == 1) {
      //   setTabIndex(index)
      // } else {
      //   openWalletModal()
      // }
    }
  }

  return (
    <Grid container spacing={4} mb={6}>
      <Grid item xs={12} md={6}>
        <CustomBox sx={{ background: 'transparent' }}>
          <Box sx={{ maxWidth: '360px', margin: '30px auto' }}>
            {isLoading || !account ? (
              <Skeleton sx={{ bgcolor: '#727eaa' }} variant="circular" width={400} height={400} />
            ) : (
              <Box sx={{ width: '400', height: '400', position: 'relative' }}>
                <PieChart data={data} lineWidth={5} paddingAngle={8} rounded />
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <Typography align="center" sx={{ color: '#727eaa' }}>Wallet Value</Typography>
                  <Typography
                    variant="h2"
                    sx={{ color: '#154ba0', fontSize: '44px', fontWeight: '700' }}
                    align="center"
                  >
                    {totalWallet}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '24px', lineHeight: '30px', color: '#154ba0' }}
                    align="center"
                  >
                    USD
                  </Typography>
                </div>
              </Box>
            )}

            <Stack flexDirection="row" alignItems="center" justifyContent="space-between" mt={5} mb={3}>
              <Stack flexDirection="row" alignItems="center" justifyContent="start" gap={2}>
                <StyledDivider color='#3ff5e8' />
                <Typography sx={{ fontSize: '20px', color: '#727eaa' }} mb={0}>
                  CSPR Balance
                </Typography>
              </Stack>
              {isLoading ? (
                <Skeleton width={50} height={15} sx={{ bgcolor: '#727eaa' }} />
              ) : (
                <Typography variant="body2" sx={{ fontSize: '20px', color: '#154ba0' }} mb={0}>
                  {toRoundedReadableNumber({
                    decimals: 9,
                    number: balance.cspr,
                    precision: 3,
                  })}{' '}
                  CSPR
                </Typography>
              )}
            </Stack>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
              <Stack flexDirection="row" alignItems="center" justifyContent="start" gap={2}>
                <StyledDivider color={'#154ba0'} />
                <Typography sx={{ fontSize: '20px', color: '#727eaa' }} mb={0}>
                  stCSPR Balance
                </Typography>
              </Stack>
              {isLoading ? (
                <Skeleton width={50} height={15} sx={{ bgcolor: '#727eaa' }} />
              ) : (
                <Typography variant="body2" sx={{ fontSize: '20px', color: '#154ba0' }} mb={0}>
                  {toRoundedReadableNumber({
                    decimals: 9,
                    number: balance.stCSPR,
                    precision: 3,
                  })}{' '}
                  stCSPR
                </Typography>
              )}
            </Stack>
          </Box>
        </CustomBox>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={5}>
          <Typography variant="h3" mb={2} sx={{ color: '#154ba0' }}>
            STATS
          </Typography>
          <CustomBox>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography sx={{ color: '#727eaa' }}>Current APR</Typography>
              <Typography variant="body2" sx={{ color: '#154ba0' }}>
                {info?.lsInfo?.apr} %
              </Typography>
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography sx={{ color: '#727eaa' }}>Current CSPR Price</Typography>
              <Typography variant="body2" sx={{ color: '#154ba0' }}>{csprPrice} USD</Typography>
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography sx={{ color: '#727eaa' }}>Current stCSPR Price</Typography>
              <Typography variant="body2" sx={{ color: '#154ba0' }}>
                {(csprPrice * new BigNumber(info?.lsInfo?.rate ?? 1).div(1e9).toNumber()).toFixed(7)} USD
              </Typography>
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography sx={{ color: '#727eaa' }}>TVL</Typography>
              <Typography variant="body2" sx={{ color: '#154ba0' }}>
                {(csprPrice * new BigNumber(info?.lsInfo?.totalStake ?? 0).div(1e9).toNumber()).toLocaleString('en-US')} USD
              </Typography>
            </Stack>
          </CustomBox>
        </Box>
        <Box>
          <Typography variant="h3" mb={3} sx={{ color: '#154ba0' }}>
            ACTIVE STAKES
          </Typography>
          <CustomBox>
            <PanelLabel />
            <StyledAssetWrap>
              {stakes?.length === 0 && <Typography sx={{ color: '#154ba0', marginTop: '1rem' }}>No active stake detected</Typography>}
              {stakes?.map((stake, index) => (
                <PanelStake key={index} stake={stake} rate={info?.lsInfo?.rate} apr={info?.lsInfo?.apr} />
              ))}
            </StyledAssetWrap>
          </CustomBox>
        </Box>
      </Grid>
    </Grid>
  )
}

export default DashboardPage
