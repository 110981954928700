import { AlertColor } from '@mui/material'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NotificationState {
  open?: boolean
  type?: AlertColor
  message?: string
  timeout?: number | null
  hash?: string | null
}

export const notificationInitialState: NotificationState = {
  open: false,
  type: 'success',
  message: '',
  timeout: 8000,
  hash: null,
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    addNotification: (_state, action: PayloadAction<NotificationState>) => ({
      ...notificationInitialState,
      ...action.payload,
      open: true,
    }),
    clearNotification: (state) => ({ ...state, open: false }),
  },
})

export const NotificationActions = NotificationSlice.actions
export const NotificationReducer = NotificationSlice.reducer
