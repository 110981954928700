import { useCallback } from 'react'
import axios from 'axios'
import getConfig from '../config'

export const useCSPRPrice = (): (() => Promise<number>) => {
  return useCallback(async (): Promise<number> => {
    let result = 0

    try {
      const response = await axios.get(`https://api-box.casperpunks.io/price`)

      if (response.status === 200 && response.data) {
        result = response.data.casper
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [])
}

export const useCSPRTotalSupply = (): (() => Promise<number>) => {
  const apiURL = getConfig().makeAPI
  return useCallback(async (): Promise<number> => {
    let result = 0

    try {
      const response = await axios.get(`${apiURL}/supply?is_cached=1`)

      if (response.status === 200 && response.data) {
        result = response.data.data.total
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [])
}