import React from 'react'
import { useGlobalModal } from 'state/modal/hooks'
import { useAccount } from 'state/wallet/hooks'
import { Box, Button, IconButton, Skeleton, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded'
import { trimName } from 'utils/utils'
import { toRoundedReadableNumber } from 'utils/number'
// @ts-ignore
import CSPRLogo from 'assets/casper-red.svg'

interface IWalletButtonProps {
  balance?: string
  isHeader?: boolean
  fullWidth?: boolean
  isLoading?: boolean
}

export function WalletButton(props: IWalletButtonProps): JSX.Element {
  const account = useAccount()
  const { openWalletModal } = useGlobalModal()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      {matches ? (
        <>
          {account ? (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                background: 'linear-gradient(180deg, rgba(5, 230, 231, 0.1) 0%, rgba(104, 137, 255, 0.1) 100%)',
                borderRadius: '12px',
              }}
            >
              {/* {props.isHeader && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 54px 0 22px',
                    mr: '-1.5rem',
                    color: 'rgb(255, 255, 255)',
                    height: 40,
                    lineHeight: '40px',
                    borderRadius: '100px',
                  }}
                >
                  <img src={CSPRLogo} alt="" style={{ maxWidth: 18 }} />
                  {props.isLoading ? (
                    <Skeleton width={80} height={12} sx={{ bgcolor: 'rgba(255, 255, 255, 0.6)', marginLeft: '8px' }} />
                  ) : (
                    <span style={{ marginLeft: '8px', fontWeight: 600 }}>
                      {toRoundedReadableNumber({
                        decimals: 9,
                        number: props.balance,
                        precision: 3,
                      })}
                    </span>
                  )}
                </Box>
              )} */}
              <Button
                variant="outlined"
                sx={{
                  padding: '4px 22px',
                  background: 'rgba(255, 255, 255, 0.1) !important',
                  fontSize: '1rem',
                  fontWeight: '400',
                }}
                onClick={() => openWalletModal()}
              >
                {/*
                // @ts-ignore */}
                {trimName(account, 5, 6, 10)}
              </Button>
            </Stack>
          ) : (
            <Button variant="outlined" sx={{padding: '4px 22px', borderRadius: '2rem', fontSize: '1rem'}} onClick={() => openWalletModal()} fullWidth={props.fullWidth}>
              Connect Wallet
            </Button>
          )}
        </>
      ) : (
        <IconButton arial-label={account} onClick={() => openWalletModal()} sx={{ color: '#ffffff' }}>
          <AccountBalanceWalletRoundedIcon fontSize="inherit" />
        </IconButton>
      )}
    </>
  )
}
