import React, { useMemo } from 'react'
import { useGlobalModal } from '../state/modal/hooks'

import Modal from './Modal'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { AppState } from '../state'
import { isTransactionRecent, useAllTransactions } from '../state/transactions/hooks'
import Transaction from './Transactions'

const renderTransactions = (transactions: string[]) => {
  return (
    <Box mt={1}>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </Box>
  )
}

const TransactionsModal = () => {
  const globalModal = useSelector((state: AppState) => state.globalModal)
  const { closeSettingsModal } = useGlobalModal()

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort((a, b) => b.addedTime - a.addedTime)
  }, [allTransactions])

  const pendingTransactions = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmedTransactions = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  return (
    <Modal open={globalModal.openSettingModal} onClose={() => closeSettingsModal()} title="Recent Transactions">
      <Box mt={3}>
        {renderTransactions(pendingTransactions)}
        {renderTransactions(confirmedTransactions)}
      </Box>
    </Modal>
  )
}

export default TransactionsModal
