import {
  FooterWrapper,
  ImageAbsolute,
  SectionWrapper,
  InputStyled,
  LinkContact
} from './Styled'
import { Box, Container, Stack, Typography } from '@mui/material'
import { FaTelegramPlane, FaTwitter, FaMediumM, FaLinkedin, FaGithub } from "react-icons/fa"
import FooterImg from 'assets/logo bg.svg'

function Footer(): JSX.Element {
  return (
    <div style={{ backgroundColor: '#08143e', marginTop: '3rem' }}>
      <Container maxWidth='xl'>
        <Box sx={{ padding: '1rem' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography>&copy; Paradiso 2023.</Typography>
            <Stack direction='row' alignItems='center' gap={3}>
              <LinkContact href='https://t.me/dotoracle' target='_blank'>
                <FaTelegramPlane />
                Telegram
              </LinkContact>
              <LinkContact href='https://twitter.com/ParadisoIO' target='_blank'>
                <FaTwitter />
                Twitter
              </LinkContact>
              <LinkContact href='https://medium.com/@dotoracle.network' target='_blank' >
                <FaMediumM />
                Medium
              </LinkContact>
              <LinkContact href='https://github.com/TheArcadiaGroup' target='_blank'>
                <FaGithub />
                Github
              </LinkContact>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </div>
    // <FooterWrapper>
    //   <div className='d-flex justify-content-between align-items-center' style={{ marginTop: '2rem' }}>
    //     <ImageAbsolute src={FooterImg} width={200} />
    //     <div>
    //       <p style={{ fontFamily: 'Montserrat', fontSize: '1.25rem', color: '#099b91' }}>
    //         Our Socials
    //       </p>
    //       <div className='d-flex flex-column flex-wrap gap-4 mt-4' style={{ height: '5rem', width: '5rem' }}>
    //         <LinkContact href='https://t.me/dotoracle' target='_blank'>
    //           <FaTelegramPlane />
    //           Telegram
    //         </LinkContact>
    //         <LinkContact href='https://twitter.com/ParadisoIO' target='_blank'>
    //           <FaTwitter />
    //           Twitter
    //         </LinkContact>
    //         <LinkContact href='https://medium.com/@dotoracle.network' target='_blank' >
    //           <FaMediumM />
    //           Medium
    //         </LinkContact>
    //         <LinkContact href='https://github.com/TheArcadiaGroup' target='_blank'>
    //           <FaGithub />
    //           Github
    //         </LinkContact>
    //       </div>
    //     </div>
    //     <div className='d-flex flex-column gap-3' >
    //     </div>
    //   </div>
    //   <p style={{ fontFamily: 'Montserrat', fontSize: '1rem', color: '#777e91', marginTop: '1rem' }}>
    //     &copy; Paradiso 2023.
    //   </p>
    // </FooterWrapper>
  )
}

export default Footer