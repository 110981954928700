import React, { useState } from 'react'
import { useGlobalModal } from 'state/modal/hooks'
import getConfig from 'config'
import { Avatar, Box, Fade, IconButton, Link as LinkMUI, Modal, Stack, Tooltip, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { trimName } from 'utils/utils'
import Copy from '../Header/Copy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import LogoutIcon from '@mui/icons-material/Logout'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { AppState } from 'state'

// New
import { ConnectorNames } from 'connectors'
import { useConnectWalletCallback, useConnectorId, useDeactivateCallback } from 'state/wallet/hooks'
import { useAccount } from 'state/wallet/hooks'

import CasperSignerLogo from 'assets/casper-logo.png'
import CasperDashLogo from 'assets/casper-dash.png'
import CasperWalletLogo from 'assets/casper-wallet.png'
import TorusWalletLogo from 'assets/torus-logo.svg'
import GosutoLogo from 'assets/gosuto-logo.png'
import WalletConnectLogo from 'assets/walletconnect-logo.png'
import { WALLETCONNECT_ACCOUNT, WALLETCONNECT_SESSION_TOPIC } from '../../constants'

export type Wallet = {
  name: string
  connector: ConnectorNames
  image: string
  disable?: boolean
}

const wallets: Wallet[] = [
  {
    name: 'Casper Wallet',
    connector: ConnectorNames.CasperWallet,
    image: CasperWalletLogo,
  },
  {
    name: 'Casper Dash',
    connector: ConnectorNames.CasperDash,
    image: CasperDashLogo,
  },
  {
    name: 'Torus',
    connector: ConnectorNames.TorusWallet,
    image: TorusWalletLogo,
  },
  {
    name: 'Gosuto Wallet',
    connector: ConnectorNames.Gosuto,
    image: GosutoLogo,
  },
  {
    name: 'Casper Signer',
    connector: ConnectorNames.CasperSigner,
    image: CasperSignerLogo,
  },
  {
    name: 'Casper Connect',
    connector: ConnectorNames.WalletConnect,
    image: WalletConnectLogo,
  },
]

export function WalletModal() {
  const globalModal = useSelector((state: AppState) => state.globalModal)
  const { closeWalletModal } = useGlobalModal()

  // New
  const account = useAccount()
  const connectWalletCallback = useConnectWalletCallback()
  const deactivate = useDeactivateCallback()
  const [isLoadingCasper, setIsLoadingCasper] = useState(false)
  const [isLoadingTorus, setIsLoadingTorus] = useState(false)
  const connectorID = useConnectorId()
  const handleSignOut = async () => {
    const connector = connectorID
    await deactivate().then((r) => closeWalletModal())
    if (connector == ConnectorNames.WalletConnect) {
      window.localStorage.removeItem(WALLETCONNECT_ACCOUNT)
      window.localStorage.removeItem(WALLETCONNECT_SESSION_TOPIC)
    }
  }

  const onConnectWallet = async (connectorID: ConnectorNames) => {
    if (connectorID === ConnectorNames.CasperSigner) {
      setIsLoadingCasper(true)
    }
    if (connectorID === ConnectorNames.TorusWallet) {
      setIsLoadingTorus(true)
    }

    await connectWalletCallback(connectorID).then((r) => closeWalletModal())
  }

  return (
    <Modal
      aria-labelledby="Wallet modal"
      open={globalModal.openWalletModal}
      onClose={() => closeWalletModal()}
      closeAfterTransition
    >
      <Fade in={globalModal.openWalletModal}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -60%)',
            width: 600,
            maxWidth: '90%',
            maxHeight: '100%',
            p: 3,
            pb: 5,
            backgroundColor: 'rgba(48, 49, 66, 0.8)',
            border: '1px solid rgba(255, 255, 255, 0.15)',
            backdropFilter: 'blur(75px)',
            borderRadius: '20px',
          }}
        >
          <Box mb={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h2">{account ? 'Connected Wallet' : 'Connect Your Wallet'}</Typography>
              <IconButton
                aria-label="close modal wallet"
                size="medium"
                sx={{ color: '#ffffff' }}
                onClick={() => closeWalletModal()}
              >
                <CloseIcon color="inherit" fontSize="medium" />
              </IconButton>
            </Stack>
          </Box>
          <Box>
            {account ? (
              <>
                <Box
                  sx={{
                    position: 'relative',
                    backgroundColor: '#202e49',
                    px: '16px',
                    py: '12px',
                    mb: '20px',
                    borderRadius: '8px',
                  }}
                >
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {/*
                     // @ts-ignore */}
                    <Typography variant="h4">{trimName(account, 15, 8, 20)}</Typography>
                    {/*
                     // @ts-ignore */}
                    <Copy toCopy={account} />
                  </Stack>
                </Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <LinkMUI
                    href={`${getConfig().explorerURL}/account/${account}`}
                    target="_blank"
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <OpenInNewIcon fontSize="small" sx={{ mr: '5px' }} />
                    View on explorer
                  </LinkMUI>
                  <Tooltip title="Disconnect Account" placement="top" arrow>
                    <IconButton aria-label="Disconnect Account" size="small" color="primary" onClick={handleSignOut}>
                      <LogoutIcon sx={{ color: '#ffffff' }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </>
            ) : (
              <>
                {wallets.map((wallet, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      height: '64px',
                      backgroundColor: 'rgba(255, 255, 255, 0.03)',
                      px: '16px',
                      py: '11px',
                      mb: '16px',
                      borderRadius: '10px',
                      border: '1px solid transparent',
                      cursor: 'pointer',
                      '&:hover': {
                        borderColor: '#05E6E7',
                      },
                    }}
                    onClick={() => onConnectWallet(wallet.connector)}
                    key={index}
                  >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Stack direction="row" alignItems="center" justifyContent="flex-start">
                        <Avatar alt="" src={wallet.image} variant="rounded" sx={{ maxWidth: '36px', height: 'auto' }} />
                        <Typography
                          variant="h5"
                          sx={{ ml: '15px', mb: 0, fontWeight: '500', fontSize: '18px', color: '#ffffff' }}
                        >
                          {wallet.name}
                        </Typography>
                        {/*<CustomBadge>{wallet?.readyState}</CustomBadge>*/}
                      </Stack>
                      <NavigateNextIcon sx={{ color: 'rgb(124, 132, 151)' }} fontSize="small" />
                    </Stack>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}
