import styled from 'styled-components/macro'

export const FooterWrapper = styled.div`
  padding: 3rem 1.5rem;
  background-color: #08143e;
  position: relative;

  @media (min-width: 992px) {
    padding: 3rem 8rem;
  }

  @media (min-width: 1200px) {
    padding: 3rem 12rem;
  }
`
export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
export const ImageAbsolute = styled.img`
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`
export const InputStyled = styled.input`
  border: none;
  color: #000;
  font-family: Montserrat;
  padding-left: 0.5rem;
  width: 90%;

  &:focus {
    outline: none;
    border: none;
  }

  @media (min-width: 768px) {
    width: 15rem;
  }
`
export const LinkContact = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Montserrat;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
`