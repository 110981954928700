import { useDispatch } from 'react-redux'
import { GlobalModalActions, GlobalModalState } from './reducer'

export const useGlobalModal = () => {
  const dispatch = useDispatch()

  const openWalletModal = () => {
    dispatch(GlobalModalActions.openWalletModal())
  }

  const closeWalletModal = () => {
    dispatch(GlobalModalActions.closeWalletModal())
  }

  const openSettingsModal = () => {
    dispatch(GlobalModalActions.openSettingsModal())
  }

  const closeSettingsModal = () => {
    dispatch(GlobalModalActions.closeSettingsModal())
  }

  const changeSetting = (setting: GlobalModalState) => {
    dispatch(GlobalModalActions.changeSetting(setting))
  }

  return { openWalletModal, closeWalletModal, openSettingsModal, closeSettingsModal, changeSetting } as const
}
