import React from 'react'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import getConfig from '../../config'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Link as LinkMUI } from '@mui/material'

interface TransactionSubmittedContentProps {
  hash: string | string[]
}

export function ConfirmationModalContent({
  topContent,
  bottomContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}): JSX.Element {
  return (
    <div>
      <div>{topContent()}</div>
      <div>{bottomContent()}</div>
    </div>
  )
}

function TransactionSubmittedContent(props: TransactionSubmittedContentProps): JSX.Element {
  const { hash } = props

  return (
    <div style={{ textAlign: 'center', color: '#154ba0' }}>
      <ArrowCircleUpIcon fontSize="large" sx={{ color: '#154ba0' }} />
      <h3 style={{color: '#154ba0'}}>Transaction Submitted</h3>
      <p style={{color: '#154ba0'}}>
        Your request will be processed when the next block has been written to the Casper Network, this can take up to 2
        minutes. Thank you for your patience.
      </p>
      {typeof hash == 'string' && (
        <LinkMUI
          href={`${getConfig().explorerURL}/deploy/${hash}`}
          target="_blank"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#154ba0' }}
        >
          <OpenInNewIcon fontSize="small" sx={{ mr: '5px', color: '#154ba0' }} />
          View on explorer
        </LinkMUI>
      )}
      {Array.isArray(hash) && (
        <>
          {hash.map((hs, index) => (
            <LinkMUI
              href={`${getConfig().explorerURL}/deploy/${hs}`}
              target="_blank"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1, color: '#154ba0' }}
              key={index}
            >
              <OpenInNewIcon fontSize="small" sx={{ mr: '5px', color: '#154ba0' }} />
              View on explorer
            </LinkMUI>
          ))}
        </>
      )}
    </div>
  )
}

export default TransactionSubmittedContent
