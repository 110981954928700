import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import { createWeb3ReactRoot, Web3ReactProvider } from '@dotoracle/web3-react-core'
import { Web3Provider } from '@ethersproject/providers'
import { NetworkContextName } from './constants'
import App from './App'
import ApplicationUpdater from './state/application/updater'
import TransactionUpdater from './state/transactions/updater'
import { Buffer } from 'buffer'
import store from 'state'
import './index.css'
import theme from './theme/theme'
import CssBaseline from '@mui/material/CssBaseline'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <TransactionUpdater />
    </>
  )
}


window.Buffer = window.Buffer || Buffer

const container = document.getElementById('root')
// @ts-ignore
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <ThemeProvider theme={theme}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <CssBaseline />
          <App />
          <Updaters />
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </ThemeProvider>,
)
