import React from 'react'
import Web3ReactManager from 'components/Web3ReactManager'

import AppRoutes from './AppRoutes'
import "bootstrap/dist/css/bootstrap.min.css";

const App: React.FC = () => {
  return (
    <Web3ReactManager>
      <AppRoutes />
    </Web3ReactManager>
  )
}

export default App
