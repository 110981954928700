const typography = {
  allVariants: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  fontFamily: [
    'Poppins',
    'sans-serif',
  ].join(','),
  h1: {
    color: '#ffffff',
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.3
  },
  h2: {
    color: '#ffffff',
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 1.3,
  },
  h3: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.2
  },
  h4: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.3,
  },
  h6: {
    fontSize: 18,
    fontWeight: 400,
  },
  body1: {
    marginBottom: '6px',
    fontSize: '16px',
    lineHeight: 1.2,
    fontWeight: 400,
  },
  body2: {
    color: '#ffffff',
    marginBottom: '6px',
    fontSize: '16px',
    lineHeight: 1.3,
    fontWeight: 700,
  },
  subtitle1: {
    marginBottom: '6px',
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '18px',
    lineHeight: 1.2,
    fontWeight: 400,
  },
  subtitle2: {
    marginBottom: '6px',
    fontSize: '1.1rem',
    lineHeight: 1.5,
    fontWeight: 700,
  },
}

export default typography
