import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Container, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import Layout from 'components/Layout'
import { StyledInput, StyledInputIcon, StyledInputWrap, StyledMaxBtn } from './Styled'
import { LoadingButton } from '@mui/lab'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { toRoundedReadableNumber } from 'utils/number'
import { getDeployFunction, waitForDeployExecution } from 'utils/utils'
import { useAccount, useConnectorId, useUserBalance, useWalletProvider } from 'state/wallet/hooks'
import getConfig from 'config'
import Big from 'big.js'
import { useTransactionAdder } from 'state/transactions/hooks'
import CustomBox from 'components/CustomBox'
import CasperLogo from 'assets/casper-gray.png'
import { CasperClient, CLByteArray, CLPublicKey, CLValueBuilder, DeployUtil, RuntimeArgs } from 'casper-js-sdk'
import axios from 'axios'
import { useActiveWeb3React, useBalance, useCSPRBalance, useCSPRPrice, useCSPRTotalSupply, useInfo } from 'hooks'
import BigNumber from 'bignumber.js'
import { useGlobalModal } from 'state/modal/hooks'
import { debounce } from 'lodash'
import { useWalletConnect } from 'state/modal/useWalletConnect'
import { ConnectorNames } from 'connectors'
import IBalance from 'type/IBalance'

const STAKING_CONTRACT_HASH = getConfig().liquidityStaking.contractPackageHash
const CHAIN_NAME = getConfig().network
const RPC = getConfig().nodeURL

enum StatusStaking {
  EnterAmount,
  InsufficientBalance,
  InvalidAmount,
  Valid,
}

interface IStakingPage {
  hasConnected: boolean
  accountConnected?: string
  onStakeSucces?: () => void
}

const StakingPage = (props: IStakingPage): JSX.Element => {
  const { hasConnected, accountConnected, onStakeSucces } = props
  const [amount, setAmount] = useState<string | number>()
  const [status, setStatus] = useState<StatusStaking>(StatusStaking.EnterAmount)
  const [marketCap, setMarketCap] = useState<number>(0)

  const [modalTitle, setModalTitle] = useState<string>('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [balanceOfUser, setBalanceOfUser] = useState<IBalance>({ cspr: '0', stCSPR: '0' })
  const [connected, setConnected] = useState(false)
  const [accConnected, setAccConnected] = useState('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoading1, setLoading1] = useState<boolean>(false)
  const [balance, setBalance] = useState<IBalance>({
    cspr: '0',
    stCSPR: '0',
  })
  const [stakeSuccess, setStakeSuccess] = useState<boolean>(false)

  const account = useAccount()
  //const { balance, isLoading } = useUserBalance()
  const { openWalletModal } = useGlobalModal()
  const { connector } = useActiveWeb3React()
  const connectorId = useConnectorId()
  const addTransaction = useTransactionAdder()
  const provider = useWalletProvider()

  const info = useInfo()
  const csprPriceCallback = useCSPRPrice()
  const totalSupplyCallback = useCSPRTotalSupply()
  const { signDeploy: walletConnectSignDeploy } = useWalletConnect(false)
  const balanceCallback = useCSPRBalance(account)
  const balanceSTCallback = useBalance(account, getConfig().liquidityStaking.contract)

  useEffect(() => {
    getBalance()
  }, [account])

  const getBalance = async () => {
    setLoading(true)
    const _balance = await balanceCallback()
    const _balanceST = await balanceSTCallback()
    setBalance({ cspr: _balance.toString(), stCSPR: _balanceST })
    setLoading(false)
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  const handleMaxInput = () => {
    const _inputAmount = new Big(Number(balance.cspr)).div(10 ** 9).toFixed()
    setAmount(Number(_inputAmount) - 15)
  }

  const onStake = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      setModalTitle(`Staking Casper`)
      const gasFee = 14000000000
      if (account) {
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, CHAIN_NAME ?? 'casper-test', 1, 1800000)
        const contractPackageHash = new CLByteArray(Uint8Array.from(Buffer.from(STAKING_CONTRACT_HASH, 'hex')))
        const _amount = new BigNumber(amount).multipliedBy(10 ** 9).toString()

        const runtimeArgs = RuntimeArgs.fromMap({
          staked_cspr_package_hash_key: CLValueBuilder.key(contractPackageHash),
          amount: CLValueBuilder.u512(_amount),
        })

        const response = await axios.get('/stake_session.wasm', {
          responseType: 'arraybuffer',
        })
        const instance = new Uint8Array(Buffer.from(response.data, 'binary'))

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(RPC)
          const deployFn = await getDeployFunction(
            account,
            casperClient,
            connectorId,
            deploy,
            provider,
            json,
            connector,
            walletConnectSignDeploy,
          )

          if (deployFn) {
            try {
              await addTransaction(deployFn, {
                summary: `Staking ${amount} CSPR`,
              })

              setTxHash(deployFn)
              setAttemptingTxn(false)
            } catch (error: any) {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              // toast.error(error)
            }
          }

          const [_deploy, _raw] = await waitForDeployExecution(casperClient, deployFn)

          if (_deploy) {
            if (connected && account == accConnected) {
              setLoading1(true)
              const _balance = await balanceCallback()
              const _balanceST = await balanceSTCallback()
              setBalanceOfUser({ cspr: _balance.toString(), stCSPR: _balanceST })
              setLoading1(false)
              const _balanceOfUser = { cspr: _balance.toString(), stCSPR: _balanceST }
              const _blanceFromStorage = localStorage.getItem('balance')
              if (_blanceFromStorage) {
                localStorage.removeItem('balance')
              }
              localStorage.setItem('balance', JSON.stringify(_balanceOfUser))
            } else {
              await getBalance()
            }
            setAmount(0)
          }
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  useEffect(() => {
    //console.log('connectde', hasConnected)
    setConnected(hasConnected)
    setAccConnected(accountConnected)
  }, [])

  useEffect(() => {
    const _balanceOfUserStr = localStorage.getItem('balance')
    const _balanceOfUser = JSON.parse(_balanceOfUserStr)
    setBalanceOfUser(_balanceOfUser)

  }, [])

  useEffect(() => {
    ; (async () => {
      const _price = await csprPriceCallback()
      const _totalSupply = await totalSupplyCallback()
      const _marketCap = Number(_price) * Number(_totalSupply)
      setMarketCap(_marketCap)
    })()
  }, [])

  useEffect(() => {
    const csprBalance = connected && account === accConnected ? balanceOfUser.cspr : new BigNumber(balance.cspr).div(1e9).toNumber()
    const _amount = Number(amount)
    const min = getConfig().minStaking
    const handleStatusChange = async () => {
      if (_amount < min) {
        setStatus(StatusStaking.InvalidAmount)
      }
      if (amount === '') {
        setStatus(StatusStaking.EnterAmount)
      }
      if (_amount > Number(csprBalance)) {
        setStatus(StatusStaking.InsufficientBalance)
      }
      if (_amount >= min && _amount < Number(csprBalance)) {
        setStatus(StatusStaking.Valid)
      }
    }

    const debouncedHandleStatusChange = debounce(handleStatusChange, 300)

    debouncedHandleStatusChange()
    return () => {
      debouncedHandleStatusChange.cancel()
    }
  }, [amount, balance])

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} sx={{ mb: '20px' }}>
          <Typography variant="h1" sx={{ marginBottom: '12px', color: '#727eaa' }}>
            Stake Casper
          </Typography>
          <Typography variant="subtitle1" color='#727eaa'>Stake CSPR and receive Liquid Staked Casper in return (stCSPR)</Typography>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <Box>
            <Typography mb={3} sx={{ color: '#727eaa' }}>
              Stake
            </Typography>
            <StyledInputWrap>
              <StyledInputIcon alt="CSPR" src={CasperLogo} />
              <StyledInput
                value={amount}
                placeholder="CSPR Amount"
                type="number"
                autoFocus
                fullWidth
                onChange={(event) => setAmount(Number(event.target.value) < 0 ? '0' : event.target.value)}
              />
              <StyledMaxBtn variant="contained" sx={{ backgroundColor: '#727eaa', color: '#fff' }} size="small" onClick={handleMaxInput}>
                MAX
              </StyledMaxBtn>
            </StyledInputWrap>
            {!account ? (
              <Button variant="contained" color="primary" onClick={() => openWalletModal()} fullWidth>
                Connect Wallet
              </Button>
            ) : (
              <>
                {status === StatusStaking.EnterAmount && (
                  <LoadingButton variant="contained" fullWidth disabled={true} loading={false}>
                    Enter CSPR amount
                  </LoadingButton>
                )}
                {status === StatusStaking.InvalidAmount && (
                  <LoadingButton variant="contained" fullWidth disabled={true} loading={false}>
                    Minimum staking amount is 500 CSPR
                  </LoadingButton>
                )}
                {status === StatusStaking.InsufficientBalance && (
                  <LoadingButton variant="contained" fullWidth disabled={true} loading={false}>
                    Insufficient CSPR Balance
                  </LoadingButton>
                )}
                {status === StatusStaking.Valid && (
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    onClick={() => onStake()}
                    disabled={false}
                    loading={false}
                  >
                    Stake
                  </LoadingButton>
                )}
              </>
            )}
            <Box
              sx={{ padding: '20px', background: 'rgba(255, 255, 255, 0.03)', borderRadius: '10px' }}
              mt={6}
              mb={6}
            >
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>You will receive</Typography>
                <Typography variant="body2" color='#154ba0'>
                  {amount === undefined
                    ? 0
                    : Number(amount) / new BigNumber(info?.lsInfo?.rate ?? '0').div(1e9).toNumber()}{' '}
                  stCSPR
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>Exchange rate</Typography>
                <Typography variant="body2" color='#154ba0'>
                  1 stCSPR = {new BigNumber(info?.lsInfo?.rate ?? '0').div(1e9).toString()} CSPR
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>Max transaction cost</Typography>
                <Typography variant="body2" color='#154ba0'>14 CSPR</Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>Delegation Fee (Fee on Rewards)</Typography>
                <Typography variant="body2" color='#154ba0'>10%</Typography> {/* //@TODO: mockup */}
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} xl={5}>
          <CustomBox mb={6}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderColor: 'rgba(255, 255, 255, 0.15)',
                    height: '39px',
                    alignSelf: 'center',
                  }}
                />
              }
              spacing={2}
            >
              <Box>
                <Stack direction="row" justifyContent="start" gap={1} alignItems="center" sx={{ mb: '12px' }}>
                  <Typography sx={{ mb: 0, fontSize: 15, color: '#727eaa' }}>
                    Available to stake
                  </Typography>
                  {!connected ? (
                    account ? (
                      !isLoading && new BigNumber(balance.cspr).div(1e9).toNumber() > getConfig().minStaking ? (
                        <span
                          style={{
                            display: 'block',
                            width: '10px',
                            height: '10px',
                            borderRadius: '10px',
                            background: '#6BE791',
                          }}
                        />
                      ) : (
                        <span
                          style={{
                            display: 'block',
                            width: '10px',
                            height: '10px',
                            borderRadius: '10px',
                            background: '#E87D0A',
                          }}
                        />
                      )
                    ) : (
                      <span
                        style={{
                          display: 'block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '10px',
                          background: '#E87D0A',
                        }}
                      />
                    )
                  ) : (
                    new BigNumber(balanceOfUser.cspr).div(1e9).toNumber() > getConfig().minStaking ? (
                      <span
                        style={{
                          display: 'block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '10px',
                          background: '#6BE791',
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          display: 'block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '10px',
                          background: '#E87D0A',
                        }}
                      />
                    )
                  )}
                </Stack>
                {!connected ? (
                  account ? (
                    isLoading ? (
                      <Skeleton sx={{ bgcolor: '#727eaa' }} />
                    ) : (
                      <Typography variant="h2" component="p" color='#154ba0'>
                        {toRoundedReadableNumber({
                          decimals: 9,
                          number: balance.cspr,
                          precision: 3,
                        })}{' '}
                        CSPR
                      </Typography>
                    )
                  ) : (
                    <Typography variant="h2" component="p" color='#154ba0'>
                      0 stCSPR
                    </Typography>
                  )
                ) : (
                  account ? (
                    account == accConnected ? (
                      isLoading1 ? (
                        <Skeleton sx={{ bgcolor: '#727eaa' }} />
                      ) : (
                        <Typography variant="h2" component="p" color='#154ba0'>
                          {toRoundedReadableNumber({
                            decimals: 9,
                            number: balanceOfUser.cspr,
                            precision: 3,
                          })}{' '}
                          CSPR
                        </Typography>
                      )
                    ) : (
                      isLoading ? (
                        <Skeleton sx={{ bgcolor: '#727eaa' }} />
                      ) : (
                        <Typography variant="h2" component="p" color='#154ba0'>
                          {toRoundedReadableNumber({
                            decimals: 9,
                            number: balance.cspr,
                            precision: 3,
                          })}{' '}
                          CSPR
                        </Typography>
                      )
                    )
                  ) : (
                    <Typography variant="h2" component="p" color='#154ba0'>
                      0 stCSPR
                    </Typography>
                  )
                )}
              </Box>
              <Box>
                <Typography sx={{ mb: '12px', fontSize: 15, color: '#727eaa' }}>
                  Staked amount
                </Typography>
                {!connected ? (
                  account ? (
                    isLoading ? (
                      <Skeleton sx={{ bgcolor: '#727eaa' }} />
                    ) : (
                      <Typography variant="h2" component="p" color='#154ba0'>
                        {toRoundedReadableNumber({
                          decimals: 9,
                          number: balance.stCSPR,
                          precision: 3,
                        })}{' '}
                        stCSPR
                      </Typography>
                    )
                  ) : (
                    <Typography variant="h2" component="p" color='#154ba0'>
                      0 stCSPR
                    </Typography>
                  )
                ) : (
                  account ? (
                    account == accConnected ? (
                      isLoading1 ? (
                        <Skeleton sx={{ bgcolor: '#727eaa' }} />
                      ) : (
                        <Typography variant="h2" component="p" color='#154ba0'>
                          {toRoundedReadableNumber({
                            decimals: 9,
                            number: balanceOfUser.stCSPR,
                            precision: 3,
                          })}{' '}
                          stCSPR
                        </Typography>
                      )
                    ) : (
                      isLoading ? (
                        <Skeleton sx={{ bgcolor: '#727eaa' }} />
                      ) : (
                        <Typography variant="h2" component="p" color='#154ba0'>
                          {toRoundedReadableNumber({
                            decimals: 9,
                            number: balance.stCSPR,
                            precision: 3,
                          })}{' '}
                          stCSPR
                        </Typography>
                      )
                    )

                  ) : (
                    <Typography variant="h2" component="p" color='#154ba0'>
                      0 stCSPR
                    </Typography>
                  )
                )}
              </Box>
              <Box>
                <Typography sx={{ mb: '12px', fontSize: 15, color: '#727eaa' }}>APY</Typography>
                <Typography variant="h2" component="p" sx={{ color: '#154ba0' }}>
                  {info?.lsInfo?.apr} %
                </Typography>
              </Box>
            </Stack>
          </CustomBox>
          <Box>
            <Typography variant="h2" mb={3} color='#154ba0'>
              Staking Statistics
            </Typography>
            <CustomBox>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>APR %</Typography>
                <Typography variant="body2" sx={{ color: '#154ba0' }}>
                  {info?.lsInfo?.apr} %
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color='#727eaa'>Total staked</Typography>
                <Typography variant="body2" color='#154ba0'>
                  {toRoundedReadableNumber({
                    decimals: 9,
                    number: info?.lsInfo?.totalStake?.toString(),
                    precision: 3,
                  })}{' '}
                  CSPR
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={11}>
                <Typography color='#727eaa'>Casper market cap</Typography>
                {marketCap === 0 ? (
                  <Skeleton width={40} height={15} sx={{ bgcolor: 'rgba(255, 255, 255, 0.7)' }} />
                ) : (
                  <Typography variant="body2" color='#154ba0'>
                    $
                    {toRoundedReadableNumber({
                      decimals: 0,
                      number: marketCap.toString(),
                      precision: 0,
                    })}
                  </Typography>
                )}
              </Stack>
            </CustomBox>
          </Box>
        </Grid>
      </Grid>

      <TransactionConfirmationModal
        isOpen={showConfirm}
        title={modalTitle}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default StakingPage
