import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// Components level 1
import { Box } from '@mui/material'
import { Toaster } from 'react-hot-toast'

// Components level 2
import Header from './components/Header'
import Footer from './components/Footer'
import Notification from './components/Notification'
import { WalletModal } from './components/Wallet'
import TransactionsModal from './components/TransactionsModal'

// Pages
import DashboardPage from 'pages/Dashboard/Dashboard'
import StakingPage from './pages/Staking/Staking'
import WithdrawalPage from './pages/Withdrawal'
import HomePage from 'pages/Dashboard'

// config
import RewardPage from './pages/Reward'

// image
import BackgroundImage from 'assets/bg_full.svg'

console.log(process.env.REACT_APP_NETWORK)

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Box sx={{ backgroundColor: '#f1f2f6', backgroundImage: `url(${BackgroundImage})` }}>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>

        {/* Global Notification */}
        <Notification />

        {/* Wallet Modal */}
        <WalletModal />

        {/* Settings Modal */}
        <TransactionsModal />

        {/* Toaster */}
        <Toaster position="top-right" />
        <Footer />
      </Box>
    </BrowserRouter>
  )
}

export default AppRoutes
