const components = {
  // AppBar
  MuiAppBar: {
    styleOverrides: {
      // Name of the slot
      root: {
        backgroundColor: 'rgba(10, 25, 41, 0.72)',
        boxShadow: 'rgb(19 47 76) 0px -1px 1px inset',
        backdropFilter: 'blur(20px)',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        '@media (min-width: 600px)': {
          minHeight: 100,
        },
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        color: '#fff',
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        fontWeight: 700,
        borderRadius: '12px',
        textTransform: 'capitalize',
      },
      containedPrimary: {
        background: 'linear-gradient(135deg, #0b8994, #3a96a8, #14499f)',
        '&:hover': {
          backgroundColor: '#6889FF',
        },
        '&.Mui-disabled': {
          opacity: 0.7,
          color: 'rgba(255, 255, 255, 0.7)',
        },
      },
      outlined: {
        borderColor: '#ffffff',
      },
      sizeMedium: {
        padding: '14px 22px',
      },
      text: {
        fontSize: '14px',
        padding: '4px 0',
        '&:hover': {
          textDecoration: 'underline',
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiLoadingButton: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: 'rgba(255, 74, 78, 0.7)',
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: '#ffffff',
        },
      },
      loading: {
        color: 'white',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: '#001e3c',
        color: '#ffffff',
      },
      outlined: {
        display: 'block',
        padding: '20px',
        backgroundColor: 'rgba(19, 47, 76, 0.5)',
        color: 'rgb(255, 255, 255)',
        border: '2px solid rgb(30, 73, 118)',
        borderRadius: '16px',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      flexContainer: {
        width: '100%',
        justifyContent: 'center',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      textColorPrimary: {
        color: '#ffffff',
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        minWidth: '320px',
        backgroundColor: '#001e3c',
        color: 'red',
        '@media (min-width: 375px)': {
          width: '360px',
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        margin: '0.3rem 0',
        borderColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      label: {
        color: '#9e9e9e',
        fontSize: '16px',
        fontWeight: 400,
        '&.Mui-active': {
          color: '#ffffff',
        },
        '&.Mui-completed': {
          color: '#ffffff',
        },
      },
      iconContainer: {
        '& .MuiStepIcon-root': {
          color: 'rgba(255, 255, 255, 0.5)',
          '&.Mui-active': {
            color: '#974FBA',
          },
          '&.Mui-completed': {
            color: '#26dc7b',
          },
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        color: '#ffffff',
        backgroundColor: '#001e3c',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#D84C77',
        },
      },
      notchedOutline: {
        borderColor: '#bdbdbd',
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        background: '#001e3c'
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      sizeMedium: {
        padding: '24px 16px',
      },
      head: {
        background: 'transparent',
        color: '#FF4A4E',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        borderBottomWidth: 0,
      },
      body: {
        color: '#ffffff',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        borderColor: 'rgba(255, 255, 255, 0.1)'
      }
    }
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        padding: '0 16px',
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '16px',
      }
    }
  }
}

export default components
