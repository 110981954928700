const palette = {
  palette: {
    mode: 'dark',
  },
  primary: {
    main: '#05E6E7',
    dark: '#05E6E7',
  }
}

export default palette
