import { PairingTypes, SessionTypes } from '@walletconnect/types'
import { useCallback, useEffect, useState } from 'react'
import SignClient from '@walletconnect/sign-client'
import getConfig from 'config'
import { WALLETCONNECT_ACCOUNT, WALLETCONNECT_SESSION_TOPIC } from '../../constants'
import { DeployUtil } from 'casper-js-sdk'
import { Web3Modal } from '@web3modal/standalone'
import { getSdkError } from '@walletconnect/utils'

export let client: SignClient = undefined

export const useWalletConnect = (showWalletConnectModal: boolean) => {
  const [pairings, setPairings] = useState<PairingTypes.Struct[]>([])
  const [session, setSession] = useState<SessionTypes.Struct>()
  const [account, setAccount] = useState<string>('')
  const chainId = 'casper:' + getConfig().network

  const disconnect = () => {
    const topic = window.localStorage.getItem(WALLETCONNECT_SESSION_TOPIC)
    window.localStorage.removeItem(WALLETCONNECT_ACCOUNT)
    window.localStorage.removeItem(WALLETCONNECT_SESSION_TOPIC)
    if (client) {
      client.disconnect({
        topic,
        reason: getSdkError('USER_DISCONNECTED'),
      })
    }
  }

  useEffect(() => {
    if (showWalletConnectModal) {
      handleWalletConnect().then(() => {
        console.log('account updated')
      })
    }
  }, [showWalletConnectModal])

  useEffect(() => {
    setAccount(window.localStorage.getItem(WALLETCONNECT_ACCOUNT) || '')
  }, [])

  const onSessionConnected = useCallback(async (_session: SessionTypes.Struct) => {
    let allNamespaceAccounts = Object.values(_session.namespaces)
      .map((namespace: any) => namespace.accounts)
      .flat()
    setSession(_session)
    allNamespaceAccounts = allNamespaceAccounts ? allNamespaceAccounts : []
    const account_ = allNamespaceAccounts[0]
    const [, , address] = account_.split(':')
    console.log('allNamespaceAccounts', _session)
    setAccount(address)
    window.localStorage.setItem(WALLETCONNECT_ACCOUNT, address)
    window.localStorage.setItem(WALLETCONNECT_SESSION_TOPIC, _session.topic)
  }, [])

  const createClient = async () => {
    if (!client) {
      client = await SignClient.init({
        projectId: getConfig().projectId,
        // optional parameters
        relayUrl: 'wss://relay.walletconnect.com',
        metadata: {
          name: 'Casper Liquid Staking',
          description: 'Casper Liquid Staking',
          url: '#',
          icons: ['https://walletconnect.com/walletconnect-logo.png'],
        },
      })
    }
  }

  useEffect(() => {
    createClient().then(() => {
      console.log('client initialized')
    })
  }, [])

  const signDeploy = async (deployJson) => {
    try {
      if (!client) {
        throw 'Client not initialized'
      }
      const topic = window.localStorage.getItem(WALLETCONNECT_SESSION_TOPIC)
      console.log('signDeploy')
      console.log('topic', topic)
      console.log('chainId', chainId)
      console.log('account', account)
      console.log('client', client)

      const result = await client.request({
        topic,
        chainId,
        request: {
          method: 'casper_sign_deploy',
          params: {
            deploy: deployJson,
            address: account,
          },
        },
      })
      const signedDeploy = DeployUtil.deployFromJson(result).unwrap()
      return signedDeploy
    } catch (e) {
      console.log('error sign deploy', e)
    }
  }

  const reset = () => {
    setSession(undefined)
    setAccount('')
  }

  const chains = ['casper:casper', 'casper:casper-test']
  const handleWalletConnect = async () => {
    // const walletConnectModal = new WalletConnectModal({
    //   projectId: getConfig().projectId,
    //   // `standaloneChains` can also be specified when calling `walletConnectModal.openModal(...)` later on.
    //   chains: chains,
    // })

    const walletConnectModal = new Web3Modal({
      projectId: getConfig().projectId,
      themeMode: 'light',
      walletConnectVersion: 2,
    })
    console.log('hehehe')
    if (!client) {
      await createClient()

      console.log('done init', client)

      client.on('session_event', (args) => {
        // Handle session events, such as "chainChanged", "accountsChanged", etc.
        console.log('session_event', 'session_ping', args)
      })

      client.on('session_update', ({ topic, params }) => {
        const { namespaces } = params
        const _session = client.session.get(topic)
        // Overwrite the `namespaces` of the existing session with the incoming one.
        const updatedSession = { ..._session, namespaces }
        // Integrate the updated session state into your dapp state.
        onSessionConnected(updatedSession)
      })

      client.on('session_ping', (args) => {
        console.log('session_ping', 'session_ping', args)
      })

      client.on('session_delete', () => {
        reset()
      })
    }

    try {
      console.log('start connecting')
      const topic = window.localStorage.getItem(WALLETCONNECT_SESSION_TOPIC)
      const pairings = [topic]
      console.log('pairing', pairings)
      const requiredNamespaces = {}
      requiredNamespaces['casper'] = {
        methods: ['casper_sign_message', 'casper_sign_deploy'],
        chains,
        events: [],
      }
      console.log("requiredNamespaces ", requiredNamespaces)
      const { uri, approval } = await client.connect({
        // Provide the namespaces and chains (e.g. `eip155` for EVM-based chains) we want to use in this session.
        requiredNamespaces,
      })
      // Open QRCode modal if a URI was returned (i.e. we're not connecting an existing pairing).
      if (uri) {
        walletConnectModal.openModal({ uri, standaloneChains: chains })
        // Await session approval from the wallet.
        const session = await approval()
        setPairings(client.pairing.getAll({ active: true }))

        console.log('session', session)
        // Handle the returned session (e.g. update UI to "connected" state).
        // * You will need to create this function *
        onSessionConnected(session)
        // // Close the QRCode modal in case it was open.
        walletConnectModal.closeModal()
      }
    } catch (e) {
      console.error('error:', e)
    }
    return client
  }
  return { pairings, session, account, client, signDeploy, disconnect }
}
