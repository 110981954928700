import { CasperServiceByJsonRPC } from 'casper-js-sdk'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useChainId } from 'state/wallet/hooks'
import { useActiveWeb3React } from 'hooks'
import { useAddPopup, useBlockNumber } from '../application/hooks'
import { AppDispatch, AppState } from '../index'
import { checkedTransaction, finalizeTransaction } from './actions'
import { getActiveRPC } from 'utils/utils'
import { getBlockInfo, getDeployInfo } from 'utils/casperClientCustom'

export function shouldCheck(
  lastBlockNumber: number,
  tx: {
    addedTime: number
    receipt?: {
      /* */
    }
    lastCheckedBlockNumber?: number
  },
): boolean {
  if (tx.receipt) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}

export default function Updater(): null {
  const { library } = useActiveWeb3React()
  const chainId = useChainId()

  const lastBlockNumber = useBlockNumber()

  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['transactions']>((_state) => _state.transactions)

  const transactions = chainId ? state[chainId] ?? {} : {}

  // show popup on confirm
  const addPopup = useAddPopup()

  useEffect(() => {
    if (!chainId || !lastBlockNumber) return
    getActiveRPC().then((RPC) => {
      const client = new CasperServiceByJsonRPC(RPC)
      Object.keys(transactions)
        .filter((hash) => shouldCheck(lastBlockNumber, transactions[hash]))
        .forEach((hash) => {
          getDeployInfo(hash)
            .then((receipt) => {
              if (receipt.execution_results.length > 0) {
                getBlockInfo(receipt.execution_results[0].block_hash).then((block) => {
                  dispatch(
                    finalizeTransaction({
                      chainId,
                      hash,
                      receipt: {
                        blockHash: receipt.execution_results[0].block_hash,
                        blockNumber: block.block?.header.height ?? lastBlockNumber,
                        // @ts-ignore
                        contractAddress: receipt.deploy.session['StoredContractByHash']?.hash,
                        from: receipt.deploy.header.account,
                        status: receipt.execution_results[0].result.Success ? 1 : 0,
                        // @ts-ignore
                        to: receipt.deploy.session['StoredContractByHash']?.hash,
                        transactionHash: receipt.deploy.hash,
                        transactionIndex: 0,
                      },
                    }),
                  )

                  addPopup(
                    {
                      txn: {
                        hash,
                        success:
                          receipt.execution_results.length > 0 &&
                          receipt.execution_results[0].result.Success != undefined,
                        summary: transactions[hash]?.summary,
                      },
                    },
                    hash,
                  )
                })
              } else {
                dispatch(checkedTransaction({ chainId, hash, blockNumber: lastBlockNumber }))
              }
            })
            .catch((error) => {
              console.error(`failed to check transaction hash: ${hash}`, error)
            })
        })
    })
  }, [chainId, library, transactions, lastBlockNumber, dispatch, addPopup])

  return null
}
