import React from 'react'
import Modal from '../Modal'
import ConfirmationPendingContent from './ConfirmationPendingContent'
import TransactionSubmittedContent from './TransactionSubmittedContent'

interface TransactionConfirmationModalProps {
  isOpen: boolean
  title: string
  attemptingTxn: boolean
  hash: string | undefined
  pendingText: string
  content: () => React.ReactNode
  onDismiss: () => void
}

function TransactionConfirmationModal(props: TransactionConfirmationModalProps): JSX.Element {
  const { isOpen, title, attemptingTxn, hash, pendingText, content, onDismiss } = props

  return (
    <>
      <Modal aria-labelledby={attemptingTxn ? '' : title} open={isOpen} onClose={() => onDismiss()} title={title}>
        <>
          {attemptingTxn ? (
            <ConfirmationPendingContent pendingText={pendingText} />
          ) : hash ? (
            <TransactionSubmittedContent hash={hash} />
          ) : (
            content()
          )}
        </>
      </Modal>
    </>
  )
}

export default TransactionConfirmationModal
