import React, { HTMLProps } from 'react'
import styled from 'styled-components/macro'
import { CircularProgress, Stack } from '@mui/material'
import { useAllTransactions } from 'state/transactions/hooks'
import getConfig from '../config'
import LaunchIcon from '@mui/icons-material/Launch'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const TransactionWrapper = styled.div`
  margin-top: 0.25rem;
`

const TransactionStatusText = styled.div`
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);

  &:hover {
    text-decoration: underline;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;

  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }

  &:active {
    text-decoration: none;
  }
`

export function ExternalLink({
  target = '_blank',
  href,
  rel = 'noopener noreferrer',
  children,
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> & { href: string }): JSX.Element {
  return (
    <StyledLink target={target} rel={rel} href={href} {...rest}>
      <span>{children}</span>
      <LaunchIcon style={{ marginLeft: '0.5rem', display: 'inline-block', verticalAlign: 'middle' }} />
    </StyledLink>
  )
}

const TransactionState = styled(ExternalLink)<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0;
  font-weight: 500;
  font-size: 0.825rem;

  svg {
    display: inline-block;
    vertical-align: middle;
  }
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? '#27ae60' : '#ff6871')};
`

export default function Transaction({ hash }: { hash: string }): JSX.Element {
  const allTransactions = useAllTransactions()
  const tx = allTransactions?.[hash]
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

  return (
    <TransactionWrapper>
      <TransactionState href={`${getConfig().explorerURL}/deploy/${hash}`} pending={pending} success={success}>
        <Stack direction="row" alignItems="center" justifyContent="start">
          <IconWrapper pending={pending} success={success}>
            {pending ? <CircularProgress sx={{ color: 'red' }} size={22} /> : success ? <CheckIcon /> : <ErrorOutlineIcon />}
          </IconWrapper>
          <TransactionStatusText>{summary ?? hash}</TransactionStatusText>
        </Stack>
      </TransactionState>
    </TransactionWrapper>
  )
}
