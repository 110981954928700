import { useCallback } from 'react'
import {
  CasperClient,
  CasperServiceByJsonRPC,
  CLPublicKey,
  CLValueBuilder,
  CLValueParsers,
  Contracts,
  encodeBase64,
  StoredValue,
} from 'casper-js-sdk'
import getConfig from 'config'
import { getActiveRPC } from 'utils/utils'
import Client, { HTTPTransport, RequestManager } from '@open-rpc/client-js'
import { getDictionaryItemByName, getStateRootHash } from 'utils/casperClientCustom'

const CHAIN_NAME = getConfig().network

export const useBalance = (account: string | null, contract: string | null): (() => Promise<any>) => {
  return useCallback(async (): Promise<any> => {
    try {
      if (!account || !contract) {
        return
      }
      const account_ = CLPublicKey.fromHex(account)

      const keyBytes = CLValueParsers.toBytes(CLValueBuilder.key(account_)).unwrap()
      const dictKey = encodeBase64(keyBytes)
      const storedValue = await getDictionaryItemByName(
        await getStateRootHash(),
        'hash-' + contract,
        'balances',
        dictKey,
      )

      if (storedValue && storedValue.CLValue) {
        return storedValue.CLValue.parsed
      }
    } catch (error: any) {
      console.error(error)
      return '0'
    }
  }, [account, contract])
}

export const useCSPRBalance = (account: string | null): (() => Promise<any>) => {
  return useCallback(async (): Promise<any> => {
    let _balance = 0
    try {
      if (!account) {
        return 0
      }
      const RPC = await getActiveRPC()
      const casper = new CasperClient(RPC)
      const casperBalance = await casper.balanceOfByPublicKey(CLPublicKey.fromHex(account))
      _balance = casperBalance.toNumber()
      return _balance
    } catch (error: any) {
      console.error(error)
      return 0
    }
  }, [account])
}
