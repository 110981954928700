import _ from 'lodash'

import Network from 'type/Network'
import { CasperClient, CasperServiceByJsonRPC, CLPublicKey, DeployUtil, GetDeployResult } from 'casper-js-sdk'
import { ConnectorNames } from '../connectors'
import { SafeEventEmitterProvider } from 'casper-js-sdk/dist/services/ProviderTransport'
import getConfig from '../config'
import axios from 'axios'
import BigNumber from 'bignumber.js'

export const isDev = process.env.REACT_APP_IS_TESTNET

export const getContractData = async (nodeAddress, stateRootHash, contractHash, path = []) => {
  const client = new CasperServiceByJsonRPC(nodeAddress)
  const blockState = await client.getBlockState(stateRootHash, `hash-${contractHash}`, path)
  return blockState
}

export const getExplorerLink = (
  network: Network | undefined,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block' | 'contract',
): string => {
  const prefix = `${network?.explorer}`

  if (network == undefined) {
    return ''
  }

  switch (type) {
    case 'transaction': {
      return `${prefix}/${network?.txUrl}/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'contract': {
      return `${prefix}/contract/${data}`
    }
    case 'address':
    default: {
      if (network.key && network.key.includes('casper')) {
        return `${prefix}/account/${data}`
      }
      return `${prefix}/address/${data}`
    }
  }
}

export const trimName = (name: string, start: number = 18, end: number = 16, total: number = 40): string => {
  if (name?.length > total) {
    return `${name.substring(0, start)}...${name.substring(name.length - end)}`
  } else {
    return name
  }
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const ipfsURI = (uri: string): string => {
  if (uri.startsWith('ipfs://')) {
    uri = uri.substring('ipfs://'.length)
    uri = `https://gateway.ipfs.io/ipfs/${uri}`
  }
  return uri
}

export const uriToHttp = (uri: string): string[] => {
  const protocol = uri.split(':')[0].toLowerCase()
  switch (protocol) {
    case 'https':
      return [uri]
    case 'http':
      return ['https' + uri.substr(4), uri]
    case 'ipfs':
      const hash = uri.match(/^ipfs:(\/\/)?(.*)$/i)?.[2]
      return [`https://cloudflare-ipfs.com/ipfs/${hash}/`, `https://ipfs.io/ipfs/${hash}/`]
    case 'ipns':
      const name = uri.match(/^ipns:(\/\/)?(.*)$/i)?.[2]
      return [`https://cloudflare-ipfs.com/ipns/${name}/`, `https://ipfs.io/ipns/${name}/`]
    default:
      return []
  }
}

export const isSelected = (list: any[], item: string): boolean => {
  if (_.isEmpty(list) || _.isEmpty(item)) return false
  return _.includes(list, item)
}

export function strToBytes(str) {
  const bytes = []
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i)
    bytes.push(code & 255)
  }
  return bytes
}

export const getDeployFunction = async (
  account: string,
  casperClient: CasperClient,
  connectorId: string,
  deploy: any,
  provider: any,
  json: any,
  connector: any,
  walletConnectSignDeploy: any,
): Promise<any> => {
  try {
    let signature: any = undefined
    let deployObject: any = undefined
    let deployFn: any = undefined

    if (connectorId === ConnectorNames.CasperSigner || connectorId === ConnectorNames.CasperDash) {
      signature = await provider.sign(json, account, account)
      const _deploy = DeployUtil.deployFromJson(signature)
      deployObject = _deploy.val
      if (deployObject instanceof DeployUtil.Deploy) {
        deployFn = casperClient.putDeploy(deployObject)
      }
    } else if (connectorId === ConnectorNames.CasperWallet) {
      signature = await provider.sign(JSON.stringify(json), account)
      deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
      deployFn = casperClient.putDeploy(deployObject)
    } else if (connectorId === ConnectorNames.WalletConnect) {
      const signedDeploy = await walletConnectSignDeploy(json)
      deployFn = casperClient.putDeploy(signedDeploy)
    } else {
      if (connector) {
        // @ts-ignore
        const { torus } = connector
        const casperService = new CasperServiceByJsonRPC(torus?.provider as SafeEventEmitterProvider)
        deployFn = casperService.deploy(deploy)
      }
    }

    return deployFn
  } catch (e) {
    console.log('error getDeployFunction', e)
  }
}

export const waitForDeployExecution = async (
  casperClient: CasperClient,
  deployHash: string,
  ticks = 1000
): Promise<[DeployUtil.Deploy, GetDeployResult]> => {
  let errorText;
  let i = 0;
  while (i !== ticks) {
    try {
      const [deploy, raw] = await casperClient.getDeploy(deployHash);
      if (raw.execution_results.length !== 0) {
        if (raw.execution_results[0].result.Success) {
          return [deploy, raw];
        } else {
          errorText =
            "Contract execution: " +
            raw.execution_results[0].result.Failure?.error_message;
          break;
        }
      } else {
        i++;
        await sleep(1000);
      }
    } catch (e) {
      i++;
      await sleep(1000);
    }
  }
  if (errorText) throw Error(errorText);
  throw Error("Timeout after " + i + "s. Something's wrong");
};

export const getStateRootHash = async () => {
  const apiURL = getConfig().makeAPI
  const RPC = await getActiveRPC()
  try {
    const response = await axios.get(`${apiURL}/rpc/info_get_status`)
    if (response.status === 200) {
      return response.data.result.last_added_block_info.state_root_hash
    }
    return ''
  } catch (error) {
    const client = new CasperServiceByJsonRPC(RPC)
    return await client.getStateRootHash()
  }
}

export const getAccountHash = (account: string | undefined | null): string => {
  if (account) {
    const _account = CLPublicKey.fromHex(account).toAccountHashStr()
    const _splitAccountHex = _account.split('-')
    return _splitAccountHex[2]
  } else {
    return ''
  }
}

export const toCSPR = (amount: string | undefined | null): string => {
  let _amount = ''
  if (amount) {
    _amount = new BigNumber(amount).div(1e9).toString()
  }
  return _amount
}

var currentRPC = ''

export const getActiveRPC = async (): Promise<string> => {
  const isTestnet = process.env.REACT_APP_NETWORK == 'testnet'
  if (isTestnet) return getConfig().nodeURL
  if (currentRPC != '') {
    return currentRPC
  }
  const apiURL = getConfig().urlAPI

  // try {
  //   const response = await axios.get(`${apiURL}/getRPC`)
  //   if (response.status === 200) {
  //     currentRPC = `https://limitless-fortress-93850-88d6ee7d4a49.herokuapp.com/${response.data.activeRPC}`
  //     return currentRPC
  //   }
  // } catch (error) {
  //   console.error('failed to get active rpc', error)
  // }
  return getConfig().nodeURL
}
