import React from 'react'
import { Box, Container } from '@mui/material'

interface ILayoutProps {
  children: React.ReactNode
  contentFullwidth?: React.ReactNode
}

const Layout = (props: ILayoutProps): JSX.Element => {
  const { children, contentFullwidth } = props
  return (
    <Box
      sx={{
        flexDirection: 'row',
        backgroundColor: 'transparent',
      }}
    >
      {contentFullwidth}
      <Container maxWidth="xl">
        <Box
          sx={{
            minHeight: 'calc(100vh - 184px)',
            paddingTop: '3rem',
            backgroundColor: 'transparent',
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  )
}

export default Layout
