import { styled } from '@mui/material/styles'
import { Avatar, AvatarProps, Button, ButtonProps, InputBase, InputBaseProps } from '@mui/material'

export const StyledInputWrap = styled('div')`
  position: relative;
  margin-bottom: 36px;
`

export const StyledInput = styled(InputBase)<InputBaseProps>(() => ({
  backgroundColor: '#f1f2f6',
  border: '1px solid #dadfee',
  padding: '18px 20px 18px 55px',
  borderRadius: '12px',
  color: '#154ba0',
  fontSize: '20px',
  lineHeight: '20px',
  '&.Mui-focused': {
    borderColor: '#154ba0',
  },
  '& .MuiInputBase-input': {
    padding: 0,
    height: '30px',
  },
  '& .MuiButton-contained': {
    position: 'absolute',
    right: '20px',
  },
}))

export const StyledInputIcon = styled(Avatar)<AvatarProps>(() => ({
  position: 'absolute',
  top: '22px',
  left: '18px',
  width: '24px',
  height: '24px',
}))

export const StyledMaxBtn = styled(Button)<ButtonProps>(() => ({
  position: 'absolute',
  top: '18px',
  right: '17px',
  background: 'linear-gradient(180deg, rgba(5, 230, 231, 0.1) 0%, rgba(104, 137, 255, 0.1) 100%)',
  color: 'rgba(255, 255, 255, 0.7)',
  borderRadius: '6px',
  fontSize: '16px',
  fontWeight: '400',
}))
