import React from 'react'
import { Snackbar, Alert } from '@mui/material'
import getConfig from '../../config'
import { useSelector } from 'react-redux'
import { AppState } from '../../state'
import { useNotification } from '../../state/notification/hooks'

const Notification = (): JSX.Element => {
  const notification = useSelector((state: AppState) => state.notification)
  const { clearNotification } = useNotification()

  const handleOpen = (url?: string) => {
    if (url) {
      window.open(`${getConfig().explorerURL}/txn/${url}`, '_blank')
    }
  }

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={notification.timeout}
      onClick={() => handleOpen(notification.hash)}
      onClose={() => clearNotification()}
    >
      <Alert
        onClose={() => clearNotification()}
        severity={notification.type}
        sx={{ width: '100%' }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  )
}

export default Notification
