import React from 'react'
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'

import { WalletButton } from '../Wallet'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useGlobalModal } from 'state/modal/hooks'

import MenuIcon from '@mui/icons-material/Menu'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
// @ts-ignore
import logo from 'assets/para-stake-logo.svg'
import { useUserBalance } from 'state/wallet/hooks'

const sxActive = {
  background: 'linear-gradient(90.72deg, #05E6E7 0.97%, #6889FF 101.62%)',
  webkitBackgroundClip: 'text',
  webkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: '#fff',
}

const pages = [
  {
    name: 'Home',
    url: 'https://paradiso.io/',
  },
  {
    name: 'Bridge',
    url: 'https://app.paradiso.io/',
  },
  {
    name: 'Docs',
    url: 'https://stcspr.docs.paradiso.io/',
  },
  {
    name: 'stCSPR Contract',
    url: 'https://cspr.live/contract-package/65cf6b0585ddd362d2c57314043b36a679af501f686771ccb67dc5976968efe0',
  }
]

const Header = (): JSX.Element => {
  const navigate = useNavigate()

  const { balance, isLoading } = useUserBalance()

  const location = useLocation()

  const { openSettingsModal } = useGlobalModal()
  const handleMenu = (url: string) => {
    navigate(url)
  }

  const handleMobileMenu = (popupState, url: string) => {
    popupState()
    navigate(url)
  }

  return (
    <AppBar position="static" elevation={0} sx={{ background: 'linear-gradient(135deg, #0b8994, #3a96a8, #14499f)' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Mobile Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <PopupState variant="popover" popupId="popup-mobile-menu">
              {(popupState) => (
                <>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    sx={{ color: '#FFF' }}
                    {...bindTrigger(popupState)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    sx={{
                      '& .MuiMenu-paper': {
                        minWidth: '200px',
                        backgroundColor: '#001e3c',
                      },
                    }}
                    {...bindMenu(popupState)}
                  >
                    {pages.map((page) => (
                      <MenuItem key={page.name} divider onClick={() => window.open(page.url, 'b_lank')}>
                        <Typography sx={{ textTransform: 'initial' }} textAlign="center">{page.name}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </PopupState>
          </Box>

          {/* Logo */}
          <Link to="/">
            <img src={logo} alt="" style={{ maxWidth: '160px' }} />
          </Link>

          {/* Desktop Menu */}
          <Box
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start', marginLeft: '3rem' }}
          >
            {pages.map((page) => (
              <Button
                variant="text"
                key={page.url}
                onClick={() => window.open(page.url, 'b_lank')}
                sx={{
                  my: 2,
                  mx: 1,
                  display: 'block',
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#fff',
                  ...(location.pathname === page.url && sxActive),
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          {/* Action Buttons */}
          <Box sx={{ display: 'flex', flexGrow: 0, ml: 2 }}>
            {/* Wallet Button */}
            <WalletButton balance={balance.cspr} isHeader={true} isLoading={isLoading} />

            {/* Settings */}
            {/* <Tooltip title="Transactions" placement="bottom" arrow>
              <IconButton
                size="medium"
                aria-label="Transactions"
                sx={{ ml: 3, borderRadius: 1, backgroundColor: '#202e49', minWidth: '50px' }}
                onClick={() => openSettingsModal()}
              >
                <NotificationsNoneIcon fontSize="small" sx={{ color: '#ffffff' }} />
              </IconButton>
            </Tooltip> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
