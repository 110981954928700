import { configureStore } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import application from './application/reducer'
import wallet from './wallet/reducer'
import transactions from './transactions/reducer'
import { NotificationReducer } from './notification/reducer'
import { GlobalModalReducer } from './modal/reducer'

const PERSISTED_KEYS: string[] = ['transactions']

const store = configureStore({
  reducer: {
    application,
    wallet,
    transactions,
    notification: NotificationReducer,
    globalModal: GlobalModalReducer,
  },
  middleware: [save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
