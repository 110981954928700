import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

interface ConfirmationPendingContentProps {
  pendingText: string
}

function ConfirmationPendingContent(props: ConfirmationPendingContentProps): JSX.Element {
  const { pendingText } = props

  return (
    <div style={{ textAlign: 'center', color: '#154ba0' }}>
      <CircularProgress sx={{ mb: 2, color: '#154ba0' }} />
      <h3 style={{color: '#154ba0'}}>Waiting For Confirmation</h3>
      <p style={{color: '#154ba0'}}>{pendingText}</p>
      <p style={{color: '#154ba0'}}>
        <small style={{color: '#154ba0'}}>Confirm this transaction in your wallet</small>
      </p>
    </div>
  )
}

export default ConfirmationPendingContent
